@import './media-queries.scss';
@import './variables.scss';
@import './mixins.scss';

/* icon styles (need to be added in base.scss ) */
[class^="icon-"]:not(.icon-content),
[class*=" icon-"]:before {
  font-family: 'HPControlIcons';
  position: relative;
  font-size: 16px;
  top: 1px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  font-smoothing: antialised;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* dropdown caret (need to be added in base.scss )*/
#drop-down {
    .dropdown-toggle {
      span {
        margin-left: 5px;
        top: 3px;
      }
  
      &:after {
        display: none;
      }
    }
  }

/* header layout styles for all screens */  
#hp-support-com  {
    .hp-header {
        .mobile-nav-toggle,
        .mobile-search-toggle {
            font-family: HPSimplified;
            &:hover, &:active {
                text-decoration: none;
            }
        }
        i.header-icon {
            font-size: 100px;
            line-height: 100px;
        }
        a#navCommunities:after {
            font-family: "HPControlIcons";
            font-size: 0.6em;
            margin-left: 12px;
            color: $light-grey;
        }
        .mobile-search-toggle {
            display: inline-block;
            height: 46px;
            line-height: 46px;
            color: $white !important;
            text-align: left;
            position: relative;
            font-family: HPSimplified;
            float: right;
            font-family: HPSimplifiedLight;
            &.out {
                right: -300px;
            }
          }
        .main-nav {
            position: relative;
            width: 100%;
            .menu {
                margin: 0;
            }
            .mobile-nav-toggle {
                display: inline-block;
                height: 46px;
                line-height: 46px;
                color: $white !important;
                text-align: left;
                position: relative;
                font-family: HPSimplified;
                padding-right: 15px;
                &.out {
                    right: 300px;
                }
                .icon-arrow {
                    position: absolute;
                    top: 0;
                    right: -10px;
                    bottom: 0;
                    left: auto;
                    margin: auto;
                    text-align: center;
                    background-position: -2px -438px;
                    background-repeat: no-repeat;
                    overflow: hidden;
                    display: block;
                    width: 10px;
                    height: 6px;
                  }
            }
        }
        .main-nav.open {
			.mobile-nav-toggle {
				.icon-arrow {
					background-position: -2px -602px;
					background-repeat: no-repeat;
					overflow: hidden;
					display: block;
					width: 10px;
					height: 6px;
				}
			}
		}
        .header-bug {
            position: absolute;
            top: 11px;
            right: 0;
            font-size: 0.9em;
            i:before {
                margin-right: 5px;
            }
        }
        #nav-secondary {
            max-width: 1000px;
            padding: 0 !important;
        }
        .customDropdown.level-2.open li a {
            border-bottom-width: 1px;
        }
        .customDropdown .mobile-nav-wrapper .container>ul>li>ul {
            display: none;
        }    
        .customDropdown .mobile-nav-wrapper .container>ul>li>ul>li>a.first-level {
            border-bottom: none;
        } 
        .customDropdown .mobile-nav-wrapper .container>ul>li>ul li {
            display: block;
        }  
        .open>.dropdown-menu {
            top: 100%;
            display: block;
            width: 100%;
            border: none;
            margin: 0px;
        }
        .open>a {
            outline: 0;
        }
          
        .open>.dropdown-menu a {
            display: block;
        } 
        .hp-dropdown.open {
            .dropdown-menu {
                top: 95%;
                box-shadow: none;
            }
            .dropdown-toggle-icon:after {
                content: '\e602';
                font-family: "HPControlIcons";
                font-size: 0.6em;
                margin-left: 12px;
                color: inherit;
            }
            .dropdown-toggle-icon-gray:after {
                content: '\e602';
                font-family: "HPControlIcons";
                font-size: 0.6em;
                margin-left: 12px;
                color: inherit;
              }
            .dropdown-toggle {
                color: $brand-color;
                background-color: $white;
                border-color: $black;
                margin-bottom: -2px;
                i {
                    border-color: $black;
                  }
              }
        }   
        .customDropdown {
            .mobile-nav-wrapper {
                .container {
                    a {
                        &.sec-nav-dv {
                            color: $white;
                            font-weight: normal;
                            background-color: $brand-color;
                        }
                        &.sec-nav-mv {
                            color: $medium-grey;
                            font-weight: normal;
                            background-color: $white;
                        }
                        &.sec-nav-mv-open {
                            color: $brand-color;
                            font-weight: bold;
                            background-color: #f2f2f2;
                        }
                        &#sec-nav-manage-contracts-and-warranties {
                            line-height: 20px;
                            padding-bottom: 30px;
                        }
                        &.login-reqd-message {
                            font-style: italic;
                            font-size: 12px;
                            color: #D7410B;
                            &:before {
                                content: "* ";
                            }
                        }
                        .login-required:before {
                            content: "* ";
                            color: #D7410B;
                        }
                    }
                    span {
                        &.secnav-dropdown-carrot {
                            vertical-align: middle;
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .bootstrap-nav {
            li {
                strong {
                    font-family: HPSimplified;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                a {
                    color: $light-grey;
                    &:hover {
                        color: $brand-color;
                    }
                }
            }
        }
        .sidebar-nav.hp-row {
            margin: 0;
        }
        .sidebar-nav {
            li.active {
                a {
                    background-color: $brand-color;
                    color: $white;
                    padding: 5px 10px;
                    span {
                        color: $white;
                    }
                }
                &:hover {
                    a {
                        background-color: $brand-color;
                        color: $white;
                        span {
                            color: $white;
                        }
                    }
                }
            }
            li {
                a {
                    padding: 5px 0;
                    display: block;
                    color: $medium-grey;
                    span {
                        float: right;
                        display: none;
                    }
                    &:hover {
                        color: $black;
                        background-color: #efefef;
                        text-decoration: none;
                    }
                }
            }
        }
        .sidebar-nav.hp-row {
            margin: 0;
        }
        .sidebar-nav {
            li.active {
                a {
                    background-color: $brand-color;
                    color: $white;
                    padding: 5px 10px;
                    span {
                        color: $white;
                    }
                }
                &:hover {
                    a {
                        background-color: $brand-color;
                        color: $white;
                        span {
                            color: $white;
                        }
                    }
                }
            }
            li {
                a {
                    padding: 5px 0;
                    display: block;
                    color: $medium-grey;
                    span {
                        float: right;
                        display: none;
                    }
                    &:hover {
                        color: $black;
                        background-color: #efefef;
                        text-decoration: none;
                    }
                }
            }
        }
        img.header-bug {
            width: 200px;
        }
          
        html[dir=rtl] .main-nav .mobile-nav-toggle .icon-arrow {
            left: 0;
            right: auto;
        }
        html[dir=rtl] {
            .mobile-nav-toggle {
                text-align: right;
                padding-left: 15px;
            }
            .mobile-search-toggle {
                text-align: right;
                float: left;
            }
        }
        
        
    }
}

// For contact solution page (video player) only
.custom-solution-video-player{
    background-color: $white1 !important;
    opacity: 1 !important;
}
//end

/* header styles for small and extra small screen */
@media #{$small-screen}, #{$extra-small-screen} {
    #hp-support-com {
        .hp-header {
          position: absolute;
          z-index: 99;
          color: $white;
          background-color: $contrast-brand-color;
          margin: 0;
           &.sticky {
            position: fixed !important;
            top: 0;
            z-index: 9999;
          }
        
          .hp-row {
            position: static;
          }
          .header-bug {
            display: none;
          }
          
          .container {
            position: relative;   
          }
          .main-nav {
            position: static;
            display: table;
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
          }
          .hp-search.hp-section {
            margin: 0;
            padding: 0;
          }
          .hp-header-search {
            background-color: $white;
            border-bottom: 1px solid $thunderstorm;
            text-align: left;
            width: 100%;
            max-width: 100%;
            margin: 0;

            .search-bar-form {
                float: left;
                button {
                    display: none;
                }
            }
            .form-group {
                position: relative;
                margin-bottom: 0;
            }
            .search-bg {
               input {
                    padding-right: 36px;
                    line-height: 36px;
                    height: 36px;
                  }
            }
            .search-bar-form-bg {
                display: table;
                width: 100%;
      
                .search-bar-form {
                  display: table-cell;
                  float: none;
                  width: 100%;
                  padding-right: 15px;
                }
      
                .mobile-search-toggle-close {
                  display: table-cell;
                  float: none;
                  text-align: center;
                  vertical-align: middle;
                }
              }
          }
          .customDropdown {
            .mobile-nav-wrapper {
                .container {
                    padding-left: 0;
                    padding-right: 0;
                    max-width: 100%;
                    a{
                        &#sec-nav-manage-contracts-and-warranties {
                          padding-bottom: 0px;
                          line-height: 40px;
                        }
                    }
                    ul {
                        margin-bottom: 0px;
                        overflow-y: auto;
                        li {
                            &:last-child {
                                padding-bottom: 0;
                            }
                            a {
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                            a.active {
                                text-decoration: none;
                            }
                            ul {
                                margin-bottom: 0;
                                ul {
                                    margin-bottom: 0;
                                }
                                li {
                                    a {
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }
                #nav-secondary {
                    ul {
                      li {
                        a {
                          span.secnav-dropdown-carrot {
                            float: right;
                          }
                        }
                        &.mob-nav-li {
                          background-color: #f2f2f2;
                          a {
                            color: $brand-color;
                          }
                          ul {
                            background: $white;
                            margin-left: -20px;
                            margin-right:-20px;
                            border-bottom: 2px solid $brand-color;
                            li {
                              background: $white;
                              &:last-child {
                                border-bottom: none;
                                a {
                                  border-bottom: none;
                                }
                              }
                              a {
                                &.login-reqd-message {
                                  color: $error;
                                  font-size: 16px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
            }
            .mobile-nav-toggle {
                padding: 0px 15px 0px 0px;
            }
			.dropdown-menu {
				li {
					display: block;
					text-align: left;
					a.active {
						font-weight: bold;
					}
				}
			}
			.dropdown-toggle {
				position: relative;
				i {
					background-position: -2px -427px;
					background-repeat: no-repeat;
					overflow: hidden;
					display: block;
					width: 10px;
					height: 6px;
					position: absolute;
					top: 0;
					right: 20px;
					bottom: 0;
					left: auto;
					margin: auto;
					text-align: center;
				}
			}
		}
		.customDropdown.open {
			.dropdown-menu {
				a {
					color: $light-grey;
					font-size: 16px;
					line-height: 16px;
				}
			}
			.dropdown-menu {
				li {
					padding-left: 20px;
					padding-right: 20px;
					border-bottom: 1px solid #dadada;
					a {
						line-height: 40px;
					}
				}
			}
		}
		.mobile-nav-wrapper {
			.customDropdown.open {
				.dropdown-toggle {
					i {
						background-position: -2px -602px;
						background-repeat: no-repeat;
						overflow: hidden;
						display: block;
						width: 10px;
						height: 6px;
					}
				}
			}
		}
		.mobile-nav-wrapper {
            .customDropdown {
                &.open {
                    .dropdown-toggle {
                        color: $white;
                        background-color: #006699;
                        i {
                            background-position: -2px -602px;
                            background-repeat: no-repeat;
                            overflow: hidden;
                            display: block;
                            width: 10px;
                            height: 6px;
                        }
                    }
                }
                .dropdown-menu {
                    border: none;
                    box-shadow: none;
                    position: static;
                    li {
                        color: $white;
                        border-bottom-color: #006699;
                    }
                    a {
                        position: relative;
                        color: $white;
                        font-size: 14px;
                        background-color: $brand-color;
                        padding-left: 30px;
                        padding-right: 30px;
                        &:before {
                            content: '-';
                            position: absolute;
                            top: 14px;
                            left: 15px;
                            right: 15px;
                        }
                    }
                }
            }
           
        }
        html[dir=rtl] {
            .customDropdown {
                .secNavToggleCarrot {
                    position: relative;
                    right: 5px;
                    top: 15px;
                    float: left;
                }
                .dropdown-menu {
                    li {
                        text-align: right;
                    }
                    a {
                        text-align: right;
                    }
                }
                .dropdown-toggle {
                    i {
                        left: 20px;
                        right: auto;
                    }
                }
            }
        }
        .dropdown-toggle.hidden-lg {
            margin-left: 20px;
        }
        #mobNavTrigSearch {
            margin-right: 20px;
        }
        
      } // hp header end
    }
}

/* header styles for medium screen */
@media #{$medium-screen} {
    // For contact solution page (video player) only 
    .app-contact-solution-window{
        overflow: visible !important;
        button.close{
            right: 0px !important;
        }
    }
    //end
    .customDropdown {
        .mobile-nav-wrapper {
            .container {
                ul {
                    li {
                        max-width: 26%;
                        ul {
                            top: 40px;
                            &:not(:last-child) {
                                /*6713 start*/
                                margin-right: 10px !important;
                                /*6713 end*/
                            }
                        }
                    }
                }
            }
        }
    }
    /* for different languages */
    html[dir=rtl] {
        .customDropdown {
            .mobile-nav-wrapper {
                .container {
                     ul {
                         li {
                             ul {
                                left: 0;
                                right: inherit;
                            }
                            &:not(:last-child) {
                                margin-left: 18px !important;
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
    html[lang=ru], html[lang=pl], html[lang=nl], html[lang=fr], html[lang=cs],
    html[lang=de], html[lang=it], html[lang=sv] {
        .customDropdown {
            .mobile-nav-wrapper {
                .container {
                     ul {
                         li {
                            max-width: 20%;
                        }
                    }
                }
            }
        }
    }
    html[lang=pt], html[lang=es], html[lang=hu], html[lang=el], html[lang=da],
    html[lang=bg] {
        .customDropdown {
            .mobile-nav-wrapper {
                .container {
                     ul {
                         li {
                            max-width: 18%;
                        }
                    }
                }
            }
        }
    }
}

/* header styles for larger screens */
@media #{$medium-screen}, #{$large-screen}, #{$extra-large-screen},
print and (min-width: 4in) {
    #hp-support-com {
        .hp-header {
            .mobile-nav-wrapper {
                display: block !important;
                border: none;
                border-bottom: 1px solid #ccc;
                box-shadow: none;
                float: none;
                min-width: 0px;
                position: static;
                width: auto;
                ul {
                    margin-left: 5px;
                }
                li {
                    border: none;
                    display: inline-block;
                    max-width: 20%;
                    position: relative;
                    ul {
                        li {
                            max-width: 100%;
                            a.login-required {
                                font-family: HPSimplified;
                            }
                            a {
                                color: $brand-color;
                                &:hover {
                                    background-color: $white;
                                    color: $brand-color;
                                }
                            }
                        }
                    }
                    a {
                        display: block;
                        color: $white;
                        line-height: normal;
                        height: 53px;
                        line-height: normal;
                        background-color: transparent;
                        border-bottom: 4px solid transparent;
                        font-family: HPSimplified;
                        &:hover {
                            background-color: $contrast-brand-color;
                            text-decoration: none;
                            color: $white;
                            border-bottom: 4px solid $white;
                        }
                    }
                    a.active {
                        color: $white;
                        border-bottom-color: $white;
                    }
                    a.active.notActive {
                        color: $white;
                        border-bottom-color: $brand-color !important;
                    }
                    &:active {
                        color: $white;
                        border-bottom-color: $white;
                    }
                    .dropdown-toggle {
                        display: block;
                        position: relative;
                        width: auto;
                        i {
                            background-position: -2px -427px;
                            background-repeat: no-repeat;
                            overflow: hidden;
                            display: block;
                            width: 10px;
                            height: 6px;
                            position: absolute;
                            float: none;
                            top: 0;
                            right: 10px;
                            left: auto;
                            bottom: 0;
                            margin: auto;
                            display: inline-block;
                        }
                    }
                    .dropdown-menu {
                        position: absolute;
                        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
                        border: 1px solid #dadada;
                        border-bottom: 4px solid $brand-color;
                        li {
                            display: block;
                            border-bottom: 1px solid #dadada;
                            a {
                                &:hover {
                                    color: $brand-color;
                                    background-color: whiteSmoke;
                                    border-bottom-color: transparent;
                                }
                            }
                        }
                    }
                }
                li.active {
                    a {
                        color: $white;
                        border-bottom-color: $white;
                    }
                }
                li.active.notActive {
                    a {
                        color: $white;
                        border-bottom-color: $contrast-brand-color !important;
                    }
                }
                li.open {
                    .dropdown-toggle {
                        background-color: $brand-color;
                        color: $white;
                        i {
                            background-position: -2px -602px;
                            background-repeat: no-repeat;
                            overflow: hidden;
                            display: block;
                            width: 10px;
                            height: 6px;
                            position: absolute;
                            float: none;
                            top: 0;
                            right: 10px;
                            left: auto;
                            bottom: 0;
                            margin: auto;
                            display: inline-block;
                        }
                    }
                }
            }
            .main-nav .mobile-nav-toggle {
                display: none;
            }
        }
        #guided {
            .hp-header {
                .mobile-nav-wrapper {
                    border-bottom: 0;
                }
            }
        }
        #nonWindows {
            .hp-header {
                .mobile-nav-wrapper {
                    border-bottom: 0;
                }
            }
        }
    }  
    html[dir=rtl] {
        .mobile-nav-wrapper {
            li {
                &.open {
                    .dropdown-toggle i {
                        left: 10px;
                        right: auto;
                      }
                }
                .dropdown-toggle i {
                    left: 10px;
                    right: auto;
                }
            }
        }
    }
      /*6713 end*/
html[dir=rtl] {
	.customDropdown {
		.mobile-nav-wrapper {
			.container {
				>ul {
					>li {
						margin-left: 25px;
						margin-right: 0;
						>ul {
							li {
								a {
									padding-right: 20px;
								}
							}
						}
					}
				}
			}
		}
	}
}
html[lang=nl], html[lang=fr]{
	.customDropdown {
		.mobile-nav-wrapper {
			.container {
				>ul {
					>li {
						>ul {
							top: 106%;
						}
					}
				}
			}
		}
	}
}

[lang=el] {
	.customDropdown {
		.mobile-nav-wrapper {
			.container {
				>ul {
					>li {
						>ul {
							top: 106%;
						}
						&:not(:last-child) {
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
}
html[lang=hu], [lang=es], [lang=bg]  {
	.customDropdown {
		.mobile-nav-wrapper {
			.container {
				>ul {
					>li {
						&:not(:last-child) {
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
}

#hp-support-com {
	.customDropdown {
		margin-top: 0px;
		.mobile-nav-wrapper {
			.container {
				>ul {
					>li {
						>a {
							color: $white;
							padding: 7px 0 0 0;
							font-size: 95%;
							height: auto;
							min-height: 40px;
							line-height: 150%;
							// border-bottom: solid 4px $brand-color;
							font-family: HPSimplified;
						}
					}
				}
				ul {
					li {
						a.active-nav {
							border-bottom: solid 4px $white;
						}
						ul {
							margin-left: 0px;
						}
					}
				}
			}
		}
	}
	.hp-header {
		.mobile-nav-wrapper {
			li {
				a {
					&:focus {
						border-bottom: solid 4px $white;
						text-decoration: none;
						outline: none;
					}
				}
			}
		}
	}
}
.customDropdown {
	.mobile-nav-wrapper {
		background-color: $contrast-brand-color;
		min-height: 40px;
		@include border-radius(0px); margin: 0px;
		padding: 0px;
		.container {
			>ul {
				margin-bottom: 0;
				>li {
					// min-height: 40px;
					&:not(:last-child) {
						margin-right: 25px;
					}
					&:hover {
						border-bottom: solid 4px $white;
					}
					>a {
						color: $white;
						padding: 0;
						font-size: 16px;
						height: 36px;
						line-height: 36px;
						border-bottom: solid 4px $brand-color;
						font-family: HPSimplifiedLight;
					}
					&:last-child {
						position: relative;
						&:hover {
							>ul {
								display: block;
							}
						}
					}
					>ul {
						position: absolute;
						z-index: 1000;
						background-color: $white;
						box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
                        width: 250px;
						border-bottom: solid 4px $brand-color;
						li {
							a {
								padding-left: 20px;
							}
						}
						>li {
							>a {
								height: 53px;
								padding-top: 15px;
								font-weight: bold;
								color: $medium-grey;
								&:not(.first-level) {
									border-bottom: solid 1px #eee;
								}
							}
							>ul {
								margin-bottom: 0;
								border-bottom: solid 1px #eee;
								margin-top: 10px;
								padding-bottom: 5px;
								>li {
									a {
										padding-top: 5px;
										padding-bottom: 5px;
										margin-left: 9px;
										text-indent: -9px;
										&:hover {
											border-bottom: solid 4px $white;
										}
									}
								}
							}
						}
					}
				}
				>li.active {
					border-bottom: solid 4px $white;
				}
			}
		}
	}
}
  
}

/* footer layout */
.footer {
    .hp_employees_container {
        display: none !important;
    }
}

/* feedback layout */
.feedbackSectionContainer{
    display: flex;
    justify-content: center;
}

/* detect device layout */
.detect-device-wrapper{
    width: 100%;
    background-color:$white2;
    margin: 30px 0 28px 0;
    padding-left: 20px;
    h2 {
      margin-bottom:0px;
    }
    &.siteHome .detect-device-container {
      background-image: url(/wcc-assets/images/identify_product_all.svg);
    }
    &.computerLanding .detect-device-container {
        background-image: url(/wcc-assets/images/identify_product_laptop.svg);
    }
    &.printerLanding .detect-device-container {
        background-image: url(/wcc-assets/images/identify_product_printer.svg);
    }
    
    .detect-device-container{
      padding: 25px 0 0 120px;
      //margin: 40px 0 30px 0px;
      background-repeat: no-repeat;
      background-size: 90px auto;
      background-position: 0px 15px;
    }
}

@media #{$extra-large-screen}{
    .detect-device-container{
      .container.no-padding {
        padding-left:0px;
        padding-right:0px;
     }
    }
}

@media #{$medium-screen}, #{$large-screen} {
    .detect-device-container{
      .container.no-padding {
        padding-left:0px;
        //padding-right:0px;
     }
    }
}

@media #{$small-screen},  #{$extra-small-screen} {
    // For contact solution page (video player) only   
    .app-contact-solution-window{
        overflow: visible !important;
        button.close{
            right: 0px !important;
        }
    }
    //end
    .detect-device-wrapper{
      .detect-device-container {
        background-image:none!important;
        padding: 25px 0 0 0px;
        .container.no-padding {
          padding-left:0px;
          //padding-right:0px;
       }
      }
      &.siteHome .detect-device-container {
        background-image: none;
        @include pfinder-sign-in-mob-style();
        }
        &.computerLanding .detect-device-container {
            background-image: none;
            @include pfinder-sign-in-mob-style();
        }
        &.printerLanding .detect-device-container {
            background-image:none;
            @include pfinder-sign-in-mob-style();
        }
    }
}


//Failure diagnostic print CSS
@media print {
    body.diag-summary-print,
    body.diag-report-print {    
        header,
        footer,
        .sec-nav-wrapper,
        .grecaptcha-badge,
        .card-wrapper.failure-daig .print-btn,
        .card-wrapper.failure-daig .warranty-status a,
        .contact-options-container,
        .support-resources-container,
        .failure-diagnostic-form-heade,
        app-frex-banner,
        .container-wrapper,
        .accordion-button,
        .ref-doc-form,
        .repair-replacement-link-container,
        #general-contact-form,
        #contact-form hr,
        img.instantInk {
            display: none !important;
        }
        .diag-report-details {
            display: block !important;
        }
    }

    // repair and exchange closure summary 
    body.diag-summary-print { 
        .contact-summery-card,
        .learn-more-container,
        .accordion-button,
        .banner {
             display: none !important;
        }
        .summary-section {
            background-image: none !important;
        }
        .product-summary-wrapper {
            .print-btn {
                display: none !important;
            }
            .product-summary-container .accordion-wrapper {
                display: block !important;
                // visibility: visible;
            }
        }
    }
}
//Check warranty print functionality
@media print {
    body.check-warranty-print {
      header,
      footer,
      .check-warranty-header,
      .print-cw-details,
      .back-btn-container,
      .add-product-section {
        display: none !important;
      }

      .print-logo,
      .page-created-on {
        display: inline-block !important;
      }
      
      .top-header {
        margin-top: 60px !important;
      }
      .print-logo {
        width: 30px;
        margin-right: 21px;
        display: block;
      }
      .warranty-title {
        width: 75% !important;
        justify-content: left;
        text-align: left;
        display: flex;
        align-items: center !important;
      }
      .page-created-on {
        width: 25% !important;
        font-family: HPSimplified !important;
        font-size: 14px !important;
        font-weight: 300 !important;
        line-height: 16px !important;
        color: $grey7 !important;
        text-align: right !important;
      }
    }

    body.diag-report-print {    
        header,
        footer,
        .grecaptcha-badge,
        .card-wrapper.failure-daig .print-btn,
        .card-wrapper.failure-daig .warranty-status a {
            display: none !important;
        }
        .failure-diagnostic-header * {
            visibility: hidden;
        }
        .failure-diagnostic-header .failure-diag-product-card, .failure-diagnostic-header .failure-diag-product-card * {
            visibility: visible;
        }
    }

    // repair and exchange closure summary 
    body.diag-summary-print { 
        .contact-summery-card,
        .learn-more-container,
        .accordion-button,
        .banner {
             display: none !important;
        }
        .container .row #contact-closure-container .summary-section {
            background-image: none;
            .closure-section {
                .product-summary-wrapper {
                    .product-summary-header {
                        .print-btn {
                            display: none;
                        }
                    }
                    .product-summary-container {
                        .accordion-wrapper {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }
  }
