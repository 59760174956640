/* pixel sizes for various screens */
$extra-small: 300px;
$small: 600px;
$medium: 768px;
$large:992px;
$extra-large: 1200px;


/* Extra small devices (phones, 600px and down) */
$extra-small-screen: 'only screen and (max-width: #{$small})';
/*  add the below line in component files to add styles for extra small screen
    for example:
    @media #{$extra-small-screen} { 
        p {
            font-size: 12px;
        }
     }
*/

/* Small devices (portrait tablets and large phones, 600px and up) */
$small-screen: 'only screen and (min-width: #{$small}) and (max-width: #{$medium - 1})';
/*  add the below line in component files to add styles for small screen
    for example:
    @media #{$small-screen} { 
         p {
            font-size: 14px;
        }
    }
*/

/* Medium devices (landscape tablets, 768px and up) */
$medium-screen: 'only screen and (min-width: #{$medium}) and (max-width: #{$large - 1})';
/*  add the below line in component files to add styles for medium screen
    for example:
    @media #{$medium-screen} { 
         p {
            font-size: 16px;
        }
    }
*/

/* Large devices (laptops/desktops, 992px and up) */
$large-screen: 'only screen and (min-width: #{$large}) and (max-width: #{$extra-large - 1})';
/*  add the below line in component files to add styles for large screen
    for example:
    @media #{$large-screen} { 
         p {
            font-size: 18px;
        }
    }
*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
$extra-large-screen: 'only screen and (min-width: #{$extra-large})';
/*  add the below line in component files to add styles for extra large screen
    for example:
    @media #{$extra-large-screen} { 
         p {
            font-size: 20px;
        }
    }
*/

/* Extra small devices (phones, 300px and down) */
$upto-extra-small-screen: 'only screen and (max-width: #{$extra-small})';

/* Small devices (portrait tablets and large phones, 600px and down) */
$upto-small-screen: 'only screen and (max-width: #{$small})';

/* Medium devices (landscape tablets, 768px and down) */
$upto-medium-screen: 'only screen and (max-width: #{$medium - 1})';

/* Large devices (laptops/desktops, 992px and down) */
$upto-large-screen: 'only screen and (max-width: #{$large})';

/* Extra large devices (large laptops and desktops, 1200px and down) */
$upto-extra-large-screen: 'only screen and (max-width: #{$extra-large})';

/* Print styles */
@media print {
    * {
        print-color-adjust: exact; /* firefox & IE */
        -webkit-print-color-adjust: exact !important;
    }
}