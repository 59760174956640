/* Primary */

$hp-blue: #0096D6;
$hp-blue-web-acc: #0171AD;
$blue-button-hover: #075782;
$hp-blue-dark: #04344E;

/* Primary New */

$hp-default-blue: #165DBA;
$hp-hover-blue: #114284;
$hp-dark-blue: #04344E;
$hp-active-blue: #0C2E5C;

/* Neutral */

$black: #000000;
$charcoal: #191919;
$light-black: #212529;
$dark-gray: #666666;
$light-gray: #adadad;
$gray-99: #999999;
$milk-white: #cecece;
$shark: #2C3038;
$medium-grey: #5A5A5A;
$light-grey: #767676;
$thunderstorm: #CCCCCC;
$concrete: #E8E8E8;
$cloud: #F3F3F3;
$porcelain: #F7F7F7;
$fog: #FAFAFA;
$white: #FFFFFF;
$grey-white: #DEE2E6;
$white-smoke: #f4f4f4;
$gray-95: #F2F2F2;
$deep-dark-gray: #212121;
$medium-dark-grey2: #404040;

// Light Theme Variables
$lighter-grey: #737373;
$link-blue: #0278AB;
$btn-hover-blue: #035C84;
$btn-click-blue: #014667;
$btn-disabled-grey: #ADADAD;
$sec-btn: #F5F5F5;
$input-text-color: #404040;
$separtator-grey: #DBDBDB;
$neutral-light: #F3FBFE;
$neutral-blue-light: #BDEAFD;
$card-blue: #EBF9FF;
// Severity color codes

$na-severity: $separtator-grey;
$low-severity: #0278AB;
$medium-severity: #D06702;
$high-severity: #B55500;
$critical-severity: #BE1313;

/* Yellow */
$premium: #C8932B;
$heat: #E88233;
$orange: #F0A83B;
$yellow: #FAE047;
$buttermilk: #FFF0B3;
$oil: #FFFAE6;

/* Yellow New */

$yellow-1: #FFF6DA;
$yellow-2: #FFEEB5;
$yellow-3: #FFE78F;
$yellow-4: #FFDE6A;
$yellow-5: #FED64D;
$yellow-6: #FFCE33;
$yellow-7: #F1C029;
$yellow-8: #E0B021;
$yellow-9: #CFA11C;
$yellow-10: #BB8F19;
$yellow-11: #011F34;

/* AMBER */

$amber-1: #FFE5CC;
$amber-2: #FFDEBC;
$amber-3: #FDD1A5;
$amber-4: #F9C086;
$amber-5: #F2A95D;
$amber-6: #E68B2D;
$amber-7: #D06702;
$amber-8: #B55500;
$amber-9: #954300;
$amber-10: #713000;
$amber-11: #4D1F00;

/* Orange */

$orange-1 : #FFE7D9;
$orange-2 : #FBC7AC;
$orange-3 : #F4A97F;
$orange-4 : #EB8D58;
$orange-5 : #DF733B;
$orange-6 : #CF5D22;
$orange-7 : #BE4813;
$orange-8 : #A93409;
$orange-9 : #8E2203;
$orange-10 : #701600;
$orange-11 : #520B00;
$orange-12 : #C94C0D;
$orange-13: #FC8900;
$orange-14: #FFF1E5;
$orange-15: #FDD2A5;
$orange-16: #D26013;
$orange-17: #874717;


/* Red */
$berry: #912940;
$gaming: #B62237;
$punch: #BF3854;
$rose: #EB4563;
$blush: #FFEBE6;

/* Red New */
$red-1: #FFE6E5;
$red-2: #FBB5B6;
$red-3: #F48787;
$red-4: #EB5E5E;
$red-5: #DF3D3D;
$red-6: #CF2323;
$red-7: #BE1313;
$red-8: #A90B0A;
$red-9: #8E0302;
$red-10: #700000;
$red-11: #011F34;
$red-12: #FF3900;
$red-13: #FEEBEA;
$red-14: #FED3D1;
$red-15: #C21E0E;
$red-16: #731B0F;


/* PINK */

$pink-1: #FFE6FA;
$pink-2: #FCB4EE;
$pink-3: #F385E1;
$pink-4: #EB5AD2;
$pink-5: #DF36C3;
$pink-6: #CF15B2;
$pink-7: #BE00A1;
$pink-8: #A9008D;
$pink-9: #8E0076;
$pink-10: #70005C;
$pink-11: #510043;
$pink-red: #dc3545;

/* PURPLE */

$purple-1: #F8E6FF;
$purple-2: #E3B3FB;
$purple-3: #CE84F4;
$purple-4: #BC58EB;
$purple-5: #A832DF;
$purple-6: #950ED0;
$purple-7: #8400EB;
$purple-8: #7300A9;
$purple-9: #60008E;
$purple-10: #4B0070;
$purple-11: #011F34;


/* BLUE */

$blue-1: #F5FBFF;
$blue-2: #D2E6F2;
$blue-3: #B2D2E6;
$blue-4: #94BFD9;
$blue-5: #79ADCD;
$blue-6: #4D8DB3;
$blue-7: #2E729A;
$blue-8: #195A81;
$blue-9: #0C4667;
$blue-10: #04344E;
$blue-11: #011F34;
$blue-12: #F0F9FF;
$blue-13: #0278AB;
$blue-14 : #115079;
$blue-15: #0278ab;
$blue-16: #002B40;
$blue-17: #014667;


/* Teal */

$teal-1: #E7FFFB;
$teal-2: #E3B3FB;
$teal-3: #8AF0DF;
$teal-4: #63E4D0;
$teal-5: #41D6BD;
$teal-6: #29C3A8;
$teal-7: #1BB3A3;
$teal-8: #0C9F98;
$teal-9: #068686;
$teal-10: #03636A;
$teal-11: #01454D;
$teal-12: #E9FFF5;

/* CHARTRESUE */

$chartreuse-1: #FCFFCF;
$chartreuse-2: #F4FE8C;
$chartreuse-3: #ECE863;
$chartreuse-4: #E4F148;
$chartreuse-5: #D8E838;
$chartreuse-6: #CADB2B;
$chartreuse-7: #BECD22;
$chartreuse-8: #AFBB1E;
$chartreuse-9: #9BA519;
$chartreuse-10: #858B15;
$chartreuse-11: #6D7111;

/* GREEN New*/
$green-1: #F0FFD9;
$green-2: #DEFFAD;
$green-3: #C7F77F;
$green-4: #ADE855;
$green-5: #90D034;
$green-6: #72B21A;
$green-7: #508C0A;
$green-8: #366C04;
$green-9: #235602;
$green-10: #184700;
$green-11: #114000;
$green-12: #3e9462;
$green-13: #B9FDBB;
$green-14: #137A17;
$green-15: #1C7A17;
$green-16: #135B08;
$green-17: #BBFFDF;
$green-18: #00D072;
$green-19: #149B53;
$green-20: #0A502F;

/* Green */

$success: #00B04F;
$spring: #2dbf6f;
$salad: #5bce8f;
$aura: #88deaf;
$mint: #b6edcf;
$smell: #e3fcef;


/* roles old*/
$brand-color: $hp-blue;
$hover-color: $blue-button-hover;
$button-color: $hp-blue-web-acc;

/* states */
$error: #BF2026;
$warning: #F0A83B;
$success: #00B04F;

/* Blue */
$dark-blue : #008A8F;

$cyan: #009B9F;
$chill: #21CCD1;
$turquoise: #00E1E6;
$aqua: #79E2F2;
$arctic: #B3F5FF;
$sky: #E6FCFF;

/* Purple */
$indigo: #4B4096;
$purple: #6B3B96;
$flower: #7D3894;
$violet: #998DD9;
$perfume: #C0B6F2;
$lavender: #F1EEFF;


/* old colors (for old pages reference only)*/

$black1: #2c3038;
$disable-bg: #d9d9d9;
$disable-color: #767676;
$redDot : #bf2026;
$blue: #0171ad;
$blue1: #075782;
$blue2: #3371c2;
$chat-btn: #6b3896;
$chat-btn-hover: #452b68;

$grey1 :#999;
$grey2: #6d6e71;
$grey3: #aeaeae;
$grey4: #ccc;
$grey5: #5a5a5a;
$grey6: #707070;
$grey7: #333;
$grey8: #EDEDED;
$grey9: #eaeaea;
$grey10: #404040;
$grey11: #212121;
$grey12: #ADADAD;


$white: #fff;
$white1: #f7f7f7;
$white2: #fafafa;
$white3 :#e8e8e8;
$white4: #efefef;
$white5: #f2f2f2;
$white6: #ced4da;
$white7: #dee2e6;
$white8: #f3f3f3;
$white9: #e5e5e5;
$white10: #f8f8f8;
$white11: #ebf9ff;
$white12: #caf3ff;
$yellowDot :#f0a83b;
$contrast-brand-color: #007dbd;
$notification-link-fcus-color: #007bff;
$light-neutral: #585858;
$hover-light: #F3FBFE;

// card shadow
$contact-card-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
$contact-card-hover-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
$background-opacity: rgba(0, 0, 0, 0.288);
$black-background-opacity : rgba(0, 0, 0, 0.2);

//fall-back color

$fallback-color: #ADADAD;
$preffered-contact-card-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18),
    inset 0px 0px 20px 10px rgba(81, 231, 44, 0.25);



// fonts

$font-family-default: 'HPSimplified';
$font-family-default-light: 'HPSimplifiedLight';
$font-family-visid: 'Forma DJR UI';
$font-family-veneer: 'Forma DJR Micro';

$font-size-12: 12px;
$font-size-14: 14px;
$font-size-normal: 16px;
$font-size-18: 18px;
$font-size-20: 20px;

$font-weight-normal: 500;
$font-weight-light: 400;
$font-weight-bold: 600;

// borders
$black-border: 1px solid $black;
$red-border: 1px solid $red-12;
$grey-border: 1px solid $grey3;
$white-border: 1px solid $white;
$transparent-border: 1px solid transparent;

/* visid support alternative colors ( https://www.figma.com/design/W86fjLb44fo2DZustbUamh/%5BSupport%5D-Foundations-Alignment?node-id=0-1&t=KH7kUYyhVAfBmlen-0 )  */

$foreground-default-color : #000000;
$foreground-medium-color : #404040;
$foreground-light-color : #666666;
$foreground-disabled-color : #A3A3A3;
$background-default-color : #FAFAFA;
$background-container-color : #FFFFFF;
$background-disabled-color : #E8E8E8;
$stroke-default-color : #212121;
$glasswall-color : rgba(33, 33, 33, 0.5);
$drop-shadow-color : rgba(33, 33, 33, 0.2);

/* spotlight colors */

$spotlight-foreground-default-color : $stroke-default-color;
$spotlight-foreground-hover-color : $stroke-default-color;
$spotlight-foreground-active-color : $stroke-default-color;
$spotlight-base-default-color : $background-container-color;
$spotlight-base-hover-color : rgba(255, 255, 255, 0.8);
$spotlight-base-active-color : rgba(255, 255, 255, 0.6);
$spotlight-background-default-color : $stroke-default-color;
$spotlight-background-hover-color : rgba(255, 255, 255, 0.1);
$spotlight-base-active-color : rgba(255, 255, 255, 0.2);

/* primary colors */

$primary-foreground-default-color : $background-container-color;
$primary-foreground-hover-color : $background-container-color;
$primary-foreground-active-color : $background-container-color;
$primary-base-default-color : $foreground-default-color;
$primary-base-hover-color : #0E3245;
$primary-base-active-color : #02476B;
$primary-background-default-color : #E6F0F5;
$primary-background-hover-color : #CEEFFE;
$primary-background-active-color : #B7E8FE;

/* neutral colors */

$neutral-foreground-default-color : $background-container-color;
$neutral-foreground-hover-color : $background-container-color;
$neutral-foreground-active-color : $background-container-color;
$neutral-base-default-color : $foreground-light-color;
$neutral-base-hover-color : #545454;
$neutral-base-active-color : $foreground-medium-color;
$neutral-background-default-color : #F1F1F1;
$neutral-background-hover-color : rgba(33, 33, 33, 0.1);
$neutral-background-active-color : $drop-shadow-color;
$neutral-background-default-color : $neutral-background-default-color;
$neutral-background-hover-color : $neutral-background-hover-color;
$neutral-background-active-color : $drop-shadow-color;

/* positive colors */

$positive-foreground-default-color : $background-container-color;
$positive-foreground-hover-color : $background-container-color;
$positive-foreground-active-color : $background-container-color;
$positive-base-default-color : #009955;
$positive-base-hover-color : #00834B;
$positive-base-active-color : #006C3E;
$positive-background-default-color : #E5FFF3;
$positive-background-hover-color : #CCFFE8;
$positive-background-active-color : #A3FFD6;

/* caution colors */

$caution-foreground-default-color : #5C4E00;
$caution-foreground-hover-color : $stroke-default-color;
$caution-foreground-active-color : $stroke-default-color;
$caution-base-default-color : #FFE137;
$caution-base-hover-color : #F9D300;
$caution-base-active-color : #E5C300;
$caution-background-default-color : #FFF8CE;
$caution-background-hover-color : #FFF29E;
$caution-background-active-color : #FFE970;


/* warning colors */

$warning-foreground-default-color : $stroke-default-color;
$warning-foreground-hover-color : $stroke-default-color;
$warning-foreground-active-color : $stroke-default-color;
$warning-base-default-color : #E07200;
$warning-base-hover-color : #B2600C;
$warning-base-active-color : #874E14;
$warning-background-default-color : #FFF8F0;
$warning-background-hover-color : #FFDBB2;
$warning-background-active-color : #FFC98C;

/* negative colors */

$negative-foreground-default-color : $background-container-color;
$negative-foreground-hover-color : $background-container-color;
$negative-foreground-active-color : $background-container-color;
$negative-base-default-color : #FF3900;
$negative-base-hover-color : #CC2C00;
$negative-base-active-color : #9F2301;
$negative-background-default-color : #FFEDEC;
$negative-background-hover-color : #FFDBD9;
$negative-background-active-color : #FFC1BD;

/* help colors */ 

$help-foreground-default-color : $background-container-color;
$help-foreground-hover-color : $background-container-color;
$help-foreground-active-color : $background-container-color;
$help-base-default-color : #0073A8;
$help-base-hover-color : #015985;
$help-base-active-color : #02476B;
$help-background-default-color : #D7F4F7;
$help-background-hover-color : #D4FBFF;
$help-background-active-color : #BCF7FF;

/* accent colors */

$accent-foreground-default-color : $background-container-color;
$accent-foreground-hover-color : $background-container-color;
$accent-foreground-active-color : $background-container-color;
$accent-base-default-color : $help-base-default-color;
$accent-base-hover-color : $help-base-hover-color;
$accent-base-active-color : $help-base-active-color;
$accent-background-default-color : $primary-background-default-color;
$accent-background-hover-color : $primary-background-hover-color;
$accent-background-active-color : $primary-background-active-color;

/* colors for reference https://www.figma.com/design/8v807D2cZ5okthnTAYngmL/WCC-HP-Vis-ID-Library?m=auto&node-id=203-375&t=7HVCimSa5FVgo4jo-1 */

$grayscale-gray-1 : #F8F8F8;
$grayscale-gray-2 : #F4F4F4;
$grayscale-gray-3 : #DBDBDB;
$grayscale-gray-4 : #ADADAD;
$grayscale-gray-5 : #737373;
$light-background : #E9FFF5;
$caution-light-background : #FFF1E5;
$caution-light : #FDD2A5;
$caution-hover-color : #FC8900;
$caution-color : #FC8900;
$caution-dark : #874717;
$warning-light-background : #FEEBEA;
$warning-light : #FED3D1;
$warning-color : #FF3900;
$warning-hover-color : #C21E0E;
$warning-dark-color : #731B0F;
$highlight-light-background : #EBF9FF;
$highlight-light : #CAF3FF;
$highlight-color : #0278AB;
$highlight-interaction-hover-color : #014667;
$highlight-interaction-dark : #002B40;
$recommended-light-background : $light-background;
$recommended-light : #BBFFDF;
$recommended-color : #00D072;
$recommended-hover-color : #149B53;
$recommended-dark : #0A502F;