
@import './media-queries.scss';
@import './variables.scss';

/* position */
.position-fixed    { position: fixed !important;}
.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }
.position-static   { position: static !important; }

/* display */

.inline         { display: inline !important;}
.block, .show   { display: block !important; }
.hide, .hidden  { display: none !important; }
.flex           { display: flex !important; }
.inline-block   { display: inline-block !important; }

/* alignment */

.align-center { text-align: center!important; align-items: center!important; }
.align-left   { text-align: left!important; align-items: left!important; }
.align-right  { text-align: right!important; align-items: right!important;}
.align-justify { text-align: justify!important; align-items: justify;}

.align-initial { align-items: initial !important;}

/* z-index */

.zindex-1 { z-index: 1 !important; }
.zindex-2 { z-index: 2!important; }
.zindex-3 { z-index: 3!important; }

/* float */
.left  { float: left!important; }
.right { float: right!important; }

/* typography */

.bold       { font-weight: bold!important; }
.regular    { font-weight: normal!important; }
.italic     { font-style: italic!important; }
.break-word { word-wrap: break-word!important; }
.no-wrap    { white-space: nowrap!important; }

/* border */

$border-color: $white;

.border-white-all    { border:        1px solid $border-color!important; }
.border-white-top    { border-top:    1px solid $border-color!important; }
.border-white-bottom { border-bottom: 1px solid $border-color!important; }
.border-white-right  { border-right:  1px solid $border-color!important; }
.border-white-left   { border-left:   1px solid $border-color!important; }

/* list styles */

.no-list-type  { list-style: none!important; }
.disc-list-type { list-style: disc!important; }

/* text transform */

.lowercase  { text-transform: lowercase!important; }
.uppercase { text-transform: uppercase!important; }
 

/* font-family */

.font-hp-simplified         { font-family: HPSimplifiedRegular!important;}
.font-hp-simplified-regular { font-family: HPSimplified!important;}
.font-hp-simplified-light   { font-family: HPSimplifiedLight!important;}
.font-hp-control-icons      { font-family: HPControlIcons!important;}
.font-arial                 { font-family: Arial!important; }

/* font-size */
  .font-12 { font-size: 12px!important; }
  .font-14 { font-size: 14px!important;}
  .font-16 { font-size: 16px!important;}
  .font-18 { font-size: 18px!important;}
  .font-20 { font-size: 20px!important;}
  .font-22 { font-size: 22px!important;}
  .font-24 { font-size: 24px!important;}
  .font-30 { font-size: 30px!important;}
  .font-35 { font-size: 35px!important;}
  
 
/* margin and padding 
   m - margin , p - padding
   t - top, l -  left, r - right, b - .border-white-bottom
   10, 15, 20 .... represents pixels
   The sass code will generate classes in the format: 
  .mt10 ,  .pl10,  .mb10, .p10 , .m20
*/

$spaceamounts: (0, 5, 10, 15, 18, 20, 25, 28, 30, 35, 40); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); 


@each $space in $spaceamounts {
  @each $side in $sides {
    
    .m#{to-upper-case(str-slice($side, 0, 1))}#{$space} { // compiles to .mT10 for margin top 10px
      margin-#{$side}: #{$space}px !important;
    }

    @media #{$small-screen}, #{$extra-small-screen} {
      .mob-m#{to-upper-case(str-slice($side, 0, 1))}#{$space} { // compiles to .mob-mT10 for margin top 10px
        margin-#{$side}: #{$space}px !important;
      }
    }

    .p#{to-upper-case(str-slice($side, 0, 1))}#{$space} { // compiles to .pT10 for padding top 10px
      padding-#{$side}: #{$space}px !important;
    }

    @media #{$small-screen}, #{$extra-small-screen} {
      .mob-p#{to-upper-case(str-slice($side, 0, 1))}#{$space} { // compiles to .mob-pT10 for margin top 10px
        padding-#{$side}: #{$space}px !important;
      }
    }
    
    
  }
}

@each $space in $spaceamounts {
    .m#{$space} {
        margin: #{$space}px !important;
    }
    .p#{$space} {
        padding: #{$space}px !important;
    }

    @media #{$small-screen}, #{$extra-small-screen} {
      .mob-m#{$space} {
        margin: #{$space}px !important;
      }
      .mob-p#{$space} {
          padding: #{$space}px !important;
      }
    }
}

@media #{$small-screen}, #{$extra-small-screen} {
  /* position for mobile */
.mob-position-fixed    { position: fixed !important; }
.mob-position-relative { position: relative !important; }
.mob-position-absolute { position: absolute !important; }
.mob-position-static   { position: static !important; }

/* display for mobile */

.mob-inline         { display: inline !important;}
.mob-block, .mob-show   { display: block !important;}
.mob-hide           { display: none !important; }
.mob-flex           { display: flex !important; }
.mob-inline-block   { display: inline-block !important; }

/* alignment for mobile */

.mob-align-center { text-align: center!important; }
.mob-align-left   { text-align: left!important; }
.mob-align-right  { text-align: right!important; }
.mob-align-justify { text-align: justify!important; }

/* z-index for mobile*/

.mob-zindex-1 { z-index: 1 !important; }
.mob-zindex-2 { z-index: 2!important; }
.mob-zindex-3 { z-index: 3!important; }

/* float for mobile*/
.mob-left  { float: left!important; }
.mob-right { float: right!important; }

/* typography for mobile*/

.mob-bold       { font-weight: bold!important; }
.mob-regular    { font-weight: normal!important; }
.mob-italic     { font-style: italic!important; }
.mob-break-word { word-wrap: break-word!important; }
.mob-no-wrap    { white-space: nowrap!important; }

/* border for mobile*/

$border-color: $white;

.mob-border-white-all    { border:        1px solid $border-color!important; }
.mob-border-white-top    { border-top:    1px solid $border-color!important; }
.mob-border-white-bottom { border-bottom: 1px solid $border-color!important; }
.mob-border-white-right  { border-right:  1px solid $border-color!important; }
.mob-border-white-left   { border-left:   1px solid $border-color!important; }

/* list styles for mobile*/

.mob-no-list-type  { list-style: none!important; }
.mob-disc-list-type { list-style: disc!important; }

/* text transform */

.mob-lowercase  { text-transform: lowercase!important; }
.mob-uppercase { text-transform: uppercase!important; }
 

/* font-family for mobile*/

.mob-font-hp-simplified         { font-family: HPSimplifiedRegular!important;}
.mob-font-hp-simplified-regular { font-family: HPSimplified!important;}
.mob-font-hp-simplified-light   { font-family: HPSimplifiedLight!important;}
.mob-font-hp-control-icons      { font-family: HPControlIcons!important;}
.mob-font-arial                 { font-family: Arial!important; }

/* font-size for mobile*/
  .mob-font-12 { font-size: 12px!important; }
  .mob-font-14 { font-size: 14px!important;}
  .mob-font-16 { font-size: 16px!important;}
  .mob-font-18 { font-size: 18px!important;}
  .mob-font-20 { font-size: 20px!important;}
  .mob-font-22 { font-size: 22px!important;}
  .mob-font-24 { font-size: 24px!important;}
  .mob-font-30 { font-size: 30px!important;}
  .mob-font-35 { font-size: 35px!important;}
}

/* old helper-styles */
  .mTB20 {
    margin-top: 20px!important;
    margin-bottom: 20px!important;
  }
  
  .margin-text {
    margin-top: 10px!important;
    margin-bottom: 15px!important;
  }
  
  .pLR30 {
    padding-left: 30px!important;
    padding-right: 30px!important;
  }

  .pb-15{
    padding-bottom: 15px !important;
  }

  .w100p{
    width: 100%!important;
  }

  .mar0{
    margin: 0!important;
  }

  .maxWidth100{
    max-width: 100%!important;
  }

  .fs22{
    font-size: 22px!important;
  }

  .fs18{
    font-size: 18px!important;
  }

  .hideText{
    visibility: hidden!important;
    line-height: 0!important;    
  }

  @media only screen and (max-width: 767px) {
      .mobBg {
          // background: #f2f2f2;
      }
      
      .mob-no-pad-RL {
          padding-right: 0px !important;
          padding-left: 0px !important;
      }
      
      .no-padding-mobile {
          padding: 0px!important;
      }

      .mob-float-none {
          float: none !important;
      }

      .mob-align-left {
          text-align: left!important;
      }

      .mob-display-block {
          display: block !important;
      }
  }

  @media only screen and (min-width:768px) {
      .desk-pL30 {
          padding-left:30px !important;
      }
      .desk-margin-auto {
          margin: 0 auto !important;
      }
      .desk-pL20 {
          padding-left: 20px !important;
      }
      .desk-border-left {
          border-left:1px solid $grey-white !important;
      }
      .no-padding-lg {
          padding: 0 !important;
      }
      .noBg {
          background: $white !important;
      }
    }
      @media only screen and (max-width:800px) {
        .hidden-mobile-only {
          display: none !important;
        }
      }
      @media only screen and (max-width:767px) {
        .hidden-mobile {
          display: none !important;
      }
  }

  /* text */
  .error-text {
    color: $error;
    font-family: HPSimplifiedRegular;
    font-size: 14px;
  }
