/*REGULAR*/
@font-face {
  font-family: 'HPSimplified';
  src: url("/wcc-assets/fonts/latin-e-regular-ttf.woff2") format("woff2"),
       url("/wcc-assets/fonts/latin-e-regular-ttf.woff") format("woff");     
  /*iOS, Android, Safari*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*BOLD*/
@font-face {
  font-family: 'HPSimplified';
  src: url("/wcc-assets/fonts/latin-e-bold-ttf.woff2") format("woff2"),
       url("/wcc-assets/fonts/latin-e-bold-ttf.woff") format("woff");       
  /*iOS, Android, Safari*/
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*LIGHT*/
@font-face {
  font-family: 'HPSimplifiedLight';
  src: url("/wcc-assets/fonts/latin-e-light-ttf.woff2") format("woff2"),
       url("/wcc-assets/fonts/latin-e-light-ttf.woff") format("woff");
  /*iOS, Android, Safari*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*REGULAR ITALIC*/
@font-face {
  font-family: 'HPSimplified';
  src: url("/wcc-assets/fonts/latin-e-regular-italic-ttf.woff2") format("woff2"),
       url("/wcc-assets/fonts/latin-e-regular-italic-ttf.woff") format("woff");
  /*iOS, Android, Safari*/
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/*BOLD ITALIC*/
@font-face {
  font-family: 'HPSimplified';
  src: url("/wcc-assets/fonts/latin-e-bold-italic-ttf.woff2") format("woff2"),
       url("/wcc-assets/fonts/latin-e-bold-italic-ttf.woff") format("woff");
  /*iOS, Android, Safari*/
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/*LIGHT ITALIC*/
@font-face {
  font-family: 'HPSimplifiedLight';
  src:  url("/wcc-assets/fonts/latin-e-light-italic-ttf.woff2") format("woff2"),
        url("/wcc-assets/fonts/latin-e-light-italic-ttf.woff") format("woff");
  /*iOS, Android, Safari*/
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* ICON FONTS*/
@font-face {
  font-family: 'HPControlIcons';
  src: url("/wcc-assets/fonts/hp_support_controls.woff2") format("woff2"),/* Super Modern Browsers */
       url('/wcc-assets/fonts/hp_support_controls.woff') format('woff'); /* Pretty Modern Browsers */
       
  /*iOS, Android, Safari*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

  
// HP Regular
@font-face {
  font-family: 'HPSimplifiedRegular';    
  src: url("/wcc-assets/fonts/latin-e-regular-italic-ttf.woff2") format("woff2"),
       url("/wcc-assets/fonts/latin-e-regular-italic-ttf.woff") format("woff");
  /*iOS, Android, Safari*/
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Visid 
@font-face {
  font-family: 'Forma DJR UI';
  src: url("/wcc-assets/fonts/FormaDJRUI.woff2") format("woff2"),
    url("/wcc-assets/fonts/FormaDJRUI.woff") format("woff");
  /*iOS, Android, Safari*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}