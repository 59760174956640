
@import './variables.scss';
@import './mixins.scss';

html[lang^='no']{
  #dvAsia {
    padding: 0px;
  }
  @media only screen and (max-width: 767px) {
    #dvAsia {
      padding: 0 15px;
    }
  }
  @include tooltip-modal-style-RTL();
}


html[lang^='he'], html[lang^='ar']  {
  @include tooltip-modal-style-RTL();

  a, span {
    &.arrow-link,
    &.arrow-link-bold {
      padding-left: 0.7rem;
      padding-right: 0;

      &:after{
        content: "\f104";
        margin-left: unset;
        margin-right: 5px;
      }
    }

    &.arrow-link-reverse, 
    &.arrow-link-reverse-bold {
      padding-right: 0.7rem;
      padding-left: 0;

      &:before {
        content: "\f105";
        margin-left: 0;
        margin-right: -0.7rem;
      }
    }
  }
  
  a, button {
    &.chevron-primary {
      &-prev,
      &-next {
        &:after {
          background-image: url(/wcc-assets/images/chevron-right-arrow-white.svg);
          background-position: 1px center;
        }
        &:disabled {
          &:after {
            background-image: url(/wcc-assets/images/chevron-right-arrow-grey.svg);
          }
        }
      }
      &-next {
        &:after {
          background-image: url(/wcc-assets/images/chevron-left-arrow-white.svg);
          background-position: 0px center;
        }
        &:disabled {
          &:after {
            background-image: url(/wcc-assets/images/chevron-left-arrow-grey.svg);
          }
        }
      }
    }
  }

  .dashboard-device-header {
    .device-header.container {
      .header-section {
        .device-info {
          .arrow-link-reverse-bold {
            padding-left: 0 !important;
            padding-right: 0.8rem;
          }
        }
      }
    }
  }

  .ribbon-banner {
    a.dashboard-link {
      span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  

  .issue-icon-dot {
    right: 5px;
  }
  .issue-icon-dot.blueDot {
    right: -9px;
  }
  .row-heading {
    width:100%;
    margin-right: 45px;
  }

  .modal-body.add-product-error {
    .add-btn {
      margin-right: 0px;
    }
  }
  
  .device-table-body  {
    div.tr {
      .first-cell {
        .table {
          .tr {
            .td:first-child {
              border-right:none!important;
            }
          }
        }
      }
    }
  }
  .tablet {
    #dvAsia {
      h3 {
        height: 72px;
      }
    }
  }

  .session-timeout-error-container .error-info a.skipLink{
    margin: 0 30px 0 0;
  }

  .modal-header {
    // position: relative;
    // height: 90px;

    .modal-logo {
      position: absolute;
      right: 15px;
    }

    // .modal-title {
    //   position: absolute;
    //   right:15px;
    // }

    #select-country-header {
      right: 93px;
      padding:0;
      padding-left: 20px;
    }

    button.close {
      position: absolute;
      left: 25px;
    }
  }

  .add-new-device-modal {
    .modal-header {
      button.close {
        left: 20px;
      }
    }
  }

  .delete-device-modal, #case-repair-order-container {
    .modal-title {
      position: relative;
    }
  }  

  .modal-body {
    #dvAmerica {
      h3 {
        text-align: right;
        height: 72px;
      }
    }

    #dvAsia {
      h3 {
        text-align: right;
        height: 72px;
      }
  
    }

    #dvEmea_africa {
      h3 {
        text-align: right;
        height: 72px;
      }
    }


    #dvAmerica h4, #dvEmea_africa h4, #dvAsia h4 {
      text-align: right;
    }
    .hp-row {
      text-align: right;
    }
  }

  .country-selector .modal-content ul.country-options {
    li {
      position: relative;

      span {
        position: absolute;
        right: 0px;
      }

      a {
        // position: absolute;
        // right: 42px;

        &.noFlagImg {
          padding-left: 0px;
          right: 0px;
        }
      }
    }
  }

  .country-selector .modal-content ul#euroList {
    li {
      position: relative;
      width: 100%;

      span {
        position: absolute;
        right: 0px;
      }

      a {
        // position: absolute;
        // right: 42px;

        &.noFlagImg {
          padding-left: 0px;
          right: 0px;
        }
      }
    }
  }
  .need-help-modal {
    .help-modal-body{
      .modal-body-content {
        .keyboard-img-container{
          img {
            &.img-arrow {
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
        }
      } 
    } 
  }
  .knowledgeSearchButton {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  @media only screen and (max-width: 800px) {
    button.search-submit:disabled {
      height:35px;
      min-height:35px;
    }
    .detect-device-wrapper.siteHome .detect-device-container{
      background-image: none;
    }
    .hidden-mobile {
        display: none;
    }
    .hidden-mobile-only {
      display: none;
  }
    #hp-support-com  {
      .search-submit {
        -webkit-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      .rtl{
        .search-submit {
          -webkit-transform: rotateY(-180deg);
          -moz-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
          left: 3px;
        }
      }
    }
  }

  .notifications-main {
    top: 161px;
    left: 42px;
    right: auto !important;

    .notification-body {
      text-align: right !important;
      display: flex;
      flex-direction: column;
    }
  }

  button#closeAlert {
    padding-left: 0px;
    left: 0px;
    text-align: left;
    right: unset;
  }

  .device-table-body {
    .td {
      &.device-serialnum {
        text-align: right;
      }
      &.device-added {
        text-align: right;
        padding-right: 10px !important;
      }
      &.device-nickname {
        text-align: right;
      }
      &.device-productname {
        text-align: right;
      }
      &.device-warrenty {
        background-position: 97% 50% !important;
        .warranty-flex {
          text-align: right !important;
          padding-right: 25px !important;
        }
      }
    } 
  }

  .device-table-header, .case-table-header , .case-table-body {
    .td {
      text-align: right;
    }
  }

  .my-case-header, .my-device-header{ 
    .export-data {
      img {
        margin-left: 4px;
        margin-right: 0px;
      }
    }
   
  } 

  .choose-os-modal {
    .modal-header .modal-title {
      right: 100px;
    }
    .modal-header button.close {
      left: 0px;
    }
    .menu{
      padding-right: 10px;
      .icon-dwn, .icon-up {
        float: left !important;
      }
    }
    .menu-list .menu-item{
      padding-right: 10px;
    }
    .selected-item {
     text-align: right;
    }
    .change-btn {
      margin-right : 30px;
    }
  }

  #close-icon {
    &.add-device-close {
      margin: -2px 0px 0px 3px;
    }
  }
    
}

.rtl {
 &.service-unavialable{
   .modal-header {
     padding: 20px 20px 20px 50px!important;
     h3.modal-title {
       right:80px!important;
     }
     button.close {
       left:30px!important;
     }
   }
   .modal-body {
     #cancelBtn 
     {
       margin-right:0px;
     }
   }
 }
  .session-timeout-error-container{
    .error-info a.skipLink {
      margin-left: 0px;
      margin-right:18px;
    }
  } 
  .deviceCard.mobileCardView .nonVA, .deviceCard.mobileCardView .casesSection{
      background-position: 95% center!important;
      padding-right: 55px;
      padding-left: 0;
      text-align: right!important;
  }

  #svg{
    transform: rotate(270deg) scale(1,1) !important;
  }
  
  .device-content-sm{
    .device-card {
      div.device-card-section {
        .device-card-row {
          div.device-cases {
            img {
              padding-left: 5px;
            }
          }
        }
      }
    } 
  } 
  .refreshIcon {
    margin-left:5px;
  }
  #welcomeHeader #profileUtilityMenuMob {
    left: 0!important;
    right: auto!important;
    text-align: right!important;
  }
  
  .customDropdown .mobile-nav-wrapper .container>ul>li {
    margin-right: 23px !important;
  }

  input:placeholder-shown {
    direction: rtl !important;
  }

  input[type="text"]:-ms-input-placeholder {
    direction: rtl !important;
  }

  .knowledgeSearchButton {
    left:3px !important;
    top: 0px !important;
  }

  div.icon-box :last-child {
    margin-left: 10px;
  }

  .tablet .heroBannerHeading {
    width: 100% !important;
    text-align: left;
  }

  .heroBannerHeading {
    width: 100% !important;
    // direction: ltr !important;
    text-align: right;
    padding: 0;
    transform: scaleX(-1);
  }

  #welcomeHeader {
    text-align: left !important;
    padding-left: 10px !important;
  }

  .hdr_widgets {
    float: left;
  }

  .header .hp_logo {
    float: right;
    margin-right: 30px;
    margin-left: 0;
  }

  button.chat-btn {
    margin-right: 30px;
  }

  #FindOutMore.mL20 {
    margin-left: 0px;
    margin-right: 20px;
  }
  #FindOutMore.mL28{
    margin-left: 0px!important;
    margin-right: 50px;
  }
  .bottom {
    #FindOutMore.mL28{
      margin-left: 1rem !important;
      margin-right: 30px;
    }
  }
  // a#FindOutMore>span:after {
  //   top: 2px;
  //   position: relative;
  // }

  .clc-header {
    position: relative;
  }

  #clc-selector {
    display: flex;
    align-items: center;
    
  }

  #clc-selector button {
    background: url('/wcc-assets/images/icons.png') -5px -226px no-repeat transparent;
    width: 25px;
  }

  .product-finder.mob-pR0 {
    padding-left: 0;
    padding-right: 15px;
  }

  #psf {
    left: 0;
    right: auto !important;
    top: 161px !important;
  }

  .mr-10-RTL {
    padding-right: 10px;
  }

  .primary-options {
    .mr-10-RTL {
      padding-right: 0 !important;
    }
  }

  .detect-device-container {
    background-position: right 15px !important;
    padding: 25px 120px 0 0px !important;
  }

  .primary-options {
    &.w50po {
      border-right: 0 !important;
      border-left: 1px solid #ccc;

      &:nth-child(2n) {
        border-left: 0 !important;
      }

      &:nth-child(1n) {
        border-right: 0 !important;
      }
    }

    &:first-child {
      border-right: 0 !important;
    }

    &:last-child {
      border-right: 0 !important;
      border-left: 0 !important;
    }

    .w50po:nth-of-type(odd) {
      border-right: 0 !important;
    }
  }

  .mainTopicImageContainer {
    margin: 0 0 0 30px !important;
  }

  .customDropdown .mobile-nav-wrapper .container span.secnav-dropdown-carrot {
    display: inline-block;
  }

  .promo-tile img.promo-arrow {
    transform: rotate(180deg);
  }

  .modal-title.with-logo {
    padding: 0 10px 0 0 !important;
    float: right !important;
    padding: 0 0 0 50px !important;
    right: 90px;
  }

  img.company-logo {
    float: right !important;
  }

  .help-modal-header button {
    float: left !important;
    left: 30px !important;
  }

  .modal-header {
    justify-content: normal;
  }

  .modal-header.help-modal-header {
    padding: 20px 20px 20px 50px !important;
  }

  .privacyPolicy {
    float: right;
  }

  .copyright {
    float: left;
  }

  .modal-body-content ul {
    padding: 0 40px 0 0 !important
  }

  .sample-image {
    text-align: left;
  }

  a.signInLink {
    padding: 0 !important;
  }

  .signInLink {
    background-position: right center !important;
    padding: 0 40px 0 0 !important;
  }

  .mda-link-wrapper {
    left: 25px !important;
    width: auto !important;
    right: auto !important;
  }

  .linkSymbol {
    padding: 0 3px 0 0 !important;
  }

  .help-modal-footer .align-left {
    float: right !important;
  }

  .help-modal-footer .align-right {
    float: left !important;
  }

  .product-finder .close-icon {
    left: 28px !important;
    right: auto!important;
    top:7px!important;
  }

  .product-finder .form-control.pfinder-input,
  input.knowledge-input, .master-pfinder .search-box .search-input input.pfinder-productnumber{
    padding: 0 15px 0 35px !important;
    direction: ltr;
    text-align: right !important;
    height: auto !important;
    min-height: 33px;
    line-height: 24px;
    font-family: inherit;
  }

  .knowledgeSearchContainer .close-icon {
    right: -35px !important;
    top: 0px;
    position: relative;
  }

  .primary-options:last-child {
    border-right: 1px solid #ccc !important;
    border-left: 0 !important;
  }

  .heroBanner {
    transform: scaleX(-1);
  }

  .heroBannerCenter{
    justify-content: flex-end;
    .heroHeadingWrapper{
      // transform: scaleX(-1);
      .heroBannerTitle{
        width: 48%;
        text-align: right !important;
      }
      .heroBannerDesp{
        text-align: right;
        padding-left: 0px;
        padding-right: 4px;
        padding-left: 0px !important;
      }
    }
    .heroBannerImageDes {
      transform: scaleX(-1);
    }
  }

  .heroBanner>div.section-wrapper {
    text-align: left !important;
  }

  #drop-down .dropdown-toggle span {
    margin-right: 7px;
  }

  .product-finder .dropdown-menu {
    width: 100%;
    right: 0px;
  }

  .width2Tile .imageDescContainer{
      text-align: left!important;
  }

  .width2Tile p.tileImgDesc{
      padding-left: 30px!important;
  }

  .dashboard-device-header {
    .device-header.container {
      .header-section {
        .device-edit-section {
          div {
            padding-right: 10px;
            a {
              span.icon-img {
                img {
                  padding-right: 0px !important;
                }
              }
            }
          }
        }
      }
    }
    .mobile{
      .device-header-container-small {
        .header-link {
          a {
            img {
              margin-left: 8px;
            }
          }
        }
        .device-header-info {
          .product-info {
            .name-serial-info {
              p {
                label {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
    } 
    
    .editModeView {
      a {
        &.cancelEdit {
          padding-right: 10px;
        }
      }
      div {
        input {
          padding-right: 45px;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .detect-device-container .productSelectSection {
      padding: 27px 30px 0 0px !important;
      border-left: 0 !important;
      border-right: 1px solid #dee2e6 !important;
    }
    .detect-device-container {
      padding: 25px 120px 0 15px !important;
    }
    .desk-border-left {
      border-left: 0;
      border-right: 1px solid #dee2e6;
      border-left:none!important;
      padding-right: 20px;
      padding-left: 15px;
    }

    .desk-pL30 {
      padding-left: 0px;
      padding-right: 30px;
    }

    .desk-pL30.border-left {
      border-right: 1px solid #dee2e6 !important;
      border-left: 0 !important;
    }

    .section-wrapper .bannerSlider.image-slider .swiper-slide .carousel-caption {
      right: 5%;
    }

    .image-slider .carousel-caption {
      text-align: right !important;
    }

    .customDropdown .mobile-nav-wrapper .container>ul>li>ul li a {
      padding-right: 15px;
      padding-left: 0px;
    }
  }

  @media only screen and (max-width: 767px) {
    .customDropdown .dropdown-menu li {
      text-align: right !important;

      .secnav-dropdown-carrot {
        float: none !important;
      }
    }

    .help-modal-footer .align-left {
      float: none !important;
    }

    .help-modal-footer .mob-align-left {
      text-align: right !important;
    }

    .help-modal-footer .align-right {
      float: none !important;
    }

    .modal-title.with-logo {
      right: 20px !important;
    }

    .submitBtnMobile {
      padding-right: 0;
      padding-left: 0;
    }

    .section-wrapper .bannerSlider.image-slider .swiper-slide .carousel-caption {
      right: 8%;
      left: 0;
    }

    .image-slider .carousel-caption {
      text-align: right !important;
    }

    button.btn-arrow {
      transform: scaleX(-1);
    }

    .section-wrapper {
      .container {
  
        .promo-container {
          .promo-bg-hover {
            height: auto;
            border-bottom: 1px solid $white;
            @include linear-gradient(to right, #00afbe 0%, #005981 100%);
            .promo-tile {
              padding: 4px 14px;
      
              p {
                left: 6% !important;
  
                margin-left: 0;
              }
      
              h2 {
                line-height: 27px;
                padding-left: 18%;
                padding-right: 0;
                right: 4%;
              }
            }
          }
        }
      }
    }

    .detect-device-wrapper {
      .detect-device-container {
        .findMoreSection {
          .product-icon-box {
            &:first-child {
              margin-right: 20px !important;
            }
          }
        }

      }
    }

    .detect-device-wrapper {
      .detect-device-container {
        .product-icon-box {
          &:first-child {
            margin-right: 0px !important;
          }
        }
      }
    }

    .icon-box {
      a {
        img {
          float: right !important;
          margin-left: 8px;
          margin-right: 10px;
        }
      }
    }

  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    
    .nonTablet .detect-device-container.section-wrapper{
        background-position: 105% 14px !important;
    }
    
  }


  // START : Dashboard Styles
  .my-devices-content .container .my-device-header .my-device-content div{
    float: left!important;
    a {
        padding-right: 22px;
        padding-left: 0px!important;
        img{
            padding-left: 10px;
            padding-right:0px!important;
        }
    }
  }
  .my-device-export a span img {
    margin-right:0px!important;
    margin-left:10px;
  }

  .device-content-sm .sort-list-box input{
    padding-right:35px;
    background-position: 2% !important;
  }
  .device-content-sm .device-card div.device-card-section .device-card-row div.device-warrenty img {
    margin-right:0px!important;
    margin-left: 10px;
  }
  .repairModalContent .closeTextbox{
    right: auto !important;
    left: 0% !important;
  }
  .repairModalContent input[type=text], input[type=email]{
    padding: 0 10px 0 30px!important;
  }
  
  @media only screen and (min-width: 801px){
    .lookup {
          margin: 0 0 0 30px;
      }
      .icon-arr{
          margin-right: 5px;
      }
      .device-table-body div.tr .table .tr .td.device-warrenty .device-warrenty-content{
          padding-right: 10px;
      }
      .device-table-body div.tr .table .tr .td.device-case div{
          padding-left: 0px;
          padding-right: 10px;
      }
      div.second-cell {
          border-right: 1px solid #e8e8e8;
          a{
              padding-left: 4px;  
          }
      }
      .device-table-body div.tr .table .tr .td:first-child {
          border: none;
      }
      .device-table-header div.second-cell {
          border-right: none;
      }
  }

  @media only screen and (max-width: 800px){
    .rtl {
      .product-finder .close-icon {
        top:3px!important;
      }
    }
    .device-content-sm {
      .device-card-row {
        background-position: right center !important;
        .device-warrenty {
          padding-right : 36px;
          padding-left: 0px !important;
        }
      }
    }
    .device-content-sm .select-box select{
        background: url("/wcc-assets/images/select-arrow.png") 4% 45% no-repeat!important;
        padding-right : 12px!important;
    }
    .dashboard-services .resource-option div div.resource-img{
        margin-right:  15px!important;
    }
    .dashboard-services .resource-option div div.resource-detail{
        margin-left: 15px!important;
    }
    &#case-repair-order-container .repairModalContent .closeTextbox{
      right: auto!important;
      left: 0!important;
    }
    .device-header-container-small .header-link a img {
      margin-left: 8px;
    }
}

}


@media only screen and (min-width: 801px) {
  .error-modal-container {
    .modal-lg {
      .rtl.service-unavialable {
        .error-modal {
          .modal-header {
            h3.modal-title {
              right: 0px;
            }
          }
        }
      }
    }
  }
  .rtl {
    .detect-device-container {
      padding: 25px 120px 0 15px !important;
    }
  }
  html[lang^='he'], html[lang^='ar']  {
    .modal-body {

      #clc-table-container {
        flex-flow: row-reverse;
        div:last-child {
          flex: 1 1 30.66%;
        }
      }
      #dvAmerica {
        //order: 1;

        ul.country-options {
          display: flex;
          flex-flow: column reverse;
          align-items: flex-end;

          li {
            width: 50%;
          }
        }
      }

      #cancelBtn, .cancelBtn {
        margin-right: 34px;
      }

      #dvAsia {
        //order: -1;

        ul#euroList {
          display: flex;
          height: 660px;
          flex-flow: column wrap-reverse;
        }
      }
    }

    .country-selector .modal-content ul.country-options {
      flex-flow: column reverse;
    }
  }

  html[lang^='ar'] {
    .modal-body {
      #dvAmerica {
        //order: 1;

        h3 {
          text-align: right;
        }

        ul.country-options {
          display: flex;
          flex-flow: column wrap-reverse;
          align-items: flex-end;

          li {
            width: 50%;
          }
        }
      }

      #cancelBtn, .cancelBtn {
        margin-right: 34px;
      }

      #dvAsia {
        //order: -1;

        h3 {
          text-align: right;
        }

        ul#euroList {
          display: flex;
          height: 660px;
          flex-flow: column wrap-reverse;
        }
      }

      #dvEmea_africa {
        h3 {
          text-align: right;
          height: 72px
        }
      }
    }

    .country-selector .modal-content ul.country-options {
      flex-flow: column wrap-reverse;
    }

    #cancelBtn, .cancelBtn {
      margin-right: 10px;
    }
  }

  
}

@media only screen and (max-width: 800px) {
 .rtl{
  #drop-down{
    a{
      &.mobile-nav-toggle{
        position:relative;
        span {
          position: absolute;
          top:16px;
          padding-right:5px;
        }
      }
    }
  }
 }
  html[lang^='he'], html[lang^='ar'] {
    .row-heading {
      width:100%;
      margin-right:0px;    
    }
    .modal-body {
      #dvAmerica {
        h3 {
          height: 72px;
        }
      }
    }

    #dvAsia {
      h3 {
        height: 72px;
      }
    }

    #removeBtn, .removeBtn {
      margin-right:0px !important;
    }

    .modal-body ul.country-options li {
      width: 100%;
    }

    .country-selector .modal-content ul.country-options {
      height: auto;
    }

    .rtl {
      .detect-device-container {
        padding: 25px 15px 0 15px !important;
      }

    }

    .delete-device-modal {
      .modal-title {
        position: absolute;
      }
    }

    .choose-os-modal {
      .modal-header {
        .modal-title {
          right: 20px;
        }
        .close {
          position: absolute !important;
          left: 15px !important;
          top: 5px !important;
        }
      }
      
      
      .dropdowns .dropdown1 , .dropdowns .dropdown2 {
        margin-right: 0px !important;
      }
      .change-btn {
        margin-right: 0px;
      }
     
    }

  }  
  
}
//Solution Page related RTL style
@media only screen and (max-width: 768px){
  .rtl {
      .solution-main-topic{
          .solution-main-topic-list{
              .container{
                  .solution-main-topic-box{
                      div.topic {
                          a{
                              div.topic-content {
                                  h2{
                                      text-align: right!important;
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      #print-main-topic{
        .solution-main-topic{
          .solution-main-topic-list{
            .container{
              .solution-main-topic-box {
                div.topic{
                  a.main-topic-option-active {
                    background-image: linear-gradient(to right, rgba(241, 238, 255, 0.04) 65%, rgba(33, 204, 209, 0.33))!important;
                  }
                }
              }
            }
          }
        }
      } 
      #computer-main-topic{
        .solution-main-topic{
          .solution-main-topic-list{
            .container{
              .solution-main-topic-box {
                div.topic{
                  a.main-topic-option-active {
                    background-image: linear-gradient(to right, rgba(241, 238, 255, 0.04) 65%, rgba(125, 56, 148, 0.31))!important;
                  }
                }
              }
            }
          }
        }
      } 
      .solution-sub-topic{
          .solution-sub-topic-list{
              .secondary-topic{
                  a.topic-close{
                      left: 15px!important;
                      right:unset!important;
                  }
              }
          }
      } 
  }
}
@media only screen and (min-width: 769px){
    .rtl{
      .solution-sub-solution-container{
        .solution-content-container{
          a.topic-close{
            left: 15px!important;
            right:unset!important;
          }
        }
      }
      .solution-slider-content{
        .slider-column {
            text-align:right!important;
            &.media-section{
              text-align:right!important;
            }
        }
      }
      
    }
}
.rtl{
  .solution-slider-content{
    .slider-column {
        text-align:right!important;
        &.media-section{
          text-align:right!important;
        }
    }
    .left-content{
      padding-right: 0px;
      padding-left: 30px;
    }
  }
  #solution-step-video{
    .solution-step-video-slider {
      .video-card-slider{
        .card-container {
          .thumbnail-container{
            margin-right:0px!important;
            margin-left:18px;
          }
        }
      } 
    }
  }
  // All Product Landing RTL Styles
  .product-category {
    &-more-tile {
      text-align: right !important;
      &:nth-child(3n) {
        margin-left: 0 !important;
      }
    }
    @media only screen and (min-width: 1200px) {
      &-more-tile {
        margin: 0px 0 25px 31px !important;
      }
      .product-finder-list.with-images li {
        margin: 0px 0px 40px 20px !important;
        &:nth-child(6n) {
          margin-left: 0;
        }
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      &-more-tile {
        margin: 0px 0 25px 18px !important;
      }
      .product-finder-list.with-images li {
        margin: 0px 0px 20px 20px !important;
        &:nth-child(4n) {
          margin-left: 0 !important;
        }
      }
    }
  }
  .product-retired {
    h5 {
      text-align: right !important;
    }
  }
  .pagination-row {
    .pagination-container {
      float: left !important;
      padding: 0 40px !important;
      .paginationText {
        margin-left: 10px;
      }
      .arrow {
        top: 0px !important;
      }
    }
  }
}

//FAl1-302 Issue -down arrow icon not in Place 
#hp-support-com{
  .rtl {
    .welcomeMessageNew{
      a.welcomeUserLink{
        i.icon-dwn{
          float: right;
          top: 10px;
          padding-left: 2px;
        }
      }
    }
  }
}

//FAL4-105 CR feedback button rotation
.rtl{
  li.opt_feedback_li{
    transform: rotate(270deg);
  }
}