/* mixins and functions */
@use "sass:math";

@mixin modal-close-link (){
	position: absolute;
    background: none;
    right:-24px;
    margin-bottom: 10px;
    margin-top: -13px;    
    color: $disable-color;
}

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
}

@mixin css-transform($param) {
	-ms-transform: $param;
  -webkit-transform: $param;
  transform: $param;
}

@mixin box-shadow ($string) {
	-webkit-box-shadow: $string;
	-moz-box-shadow:    $string;
	box-shadow: $string;
}

@mixin border-radius-nonuniform($tr,$rr,$br,$lr) {
	border-radius: $tr $rr $br $lr;
	-webkit-border-radius: $tr $rr $br $lr;
	-moz-border-radius: $tr $rr $br $lr;
}

@mixin font-style($fontFamily, $fontSize, $fontWeight, $lineHeight, $fontColor) {
	font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;
    color: $fontColor;
}

@mixin font-style-link($fontFamily, $fontSize, $fontWeight, $lineHeight) {
	font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;
}

@mixin content-margin-spacing($margin, $letterSpacing) {
	margin: $margin;
    letter-spacing: $letterSpacing;
}

@mixin modal-header-style-case() {
	.modal-header{
		border-width: 2px;
		button.close {
		  font-size: 37px;
		  span#close-icon {
			top:0px;
		  }
		  &:focus {
			span {
			  border: 1px solid $brand-color;
			}
		  }
		}
		#modal-heading{
		  margin-left: 18px;
		  font-family: HPSimplifiedLight;
		  span {
			font-family: HPSimplified;
		  }
		}
	  }
}

@function convert-angle($value, $unit) {
	$convertable-units: deg grad turn rad;
	$conversion-factors: 1 math.div(10grad, 9deg) math.div(1turn, 360deg) math.div(3.1415926rad, 180deg);
	@if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
	  @return math.div($value, nth($conversion-factors, index($convertable-units, unit($value))))
			   * nth($conversion-factors, index($convertable-units, $unit));
	}
	
	@warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
  }
  
  /// Test if `$value` is an angle
  /// @param {*} $value - Value to test
  /// @return {Bool}
  @function is-direction($value) {
	$is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
	$is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
	
	@return $is-direction or $is-angle;
  }
  
  /// Convert a direction to legacy syntax
  /// @param {Keyword | Angle} $value - Value to convert
  /// @require {function} is-direction
  /// @require {function} convert-angle
  @function legacy-direction($value) {
	@if is-direction($value) == false {
	  @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
	}
	
	$conversion-map: ( 
	  to top          : bottom,
	  to top right    : bottom left,
	  to right top    : left bottom,
	  to right        : left,
	  to bottom right : top left,
	  to right bottom : left top,
	  to bottom       : top,
	  to bottom left  : top right,
	  to left bottom  : right top,
	  to left         : right,
	  to left top     : right bottom,
	  to top left     : bottom right
	);
	  
	@if map-has-key($conversion-map, $value) {
	  @return map-get($conversion-map, $value);
	}
	
	@return 90deg - convert-angle($value, 'deg');
  }
  
  /// Mixin printing a linear-gradient 
  /// as well as a plain color fallback 
  /// and the `-webkit-` prefixed declaration
  /// @access public
  /// @param {String | List | Angle} $direction - Linear gradient direction
  /// @param {Arglist} $color-stops - List of color-stops composing the gradient
  @mixin linear-gradient($direction, $color-stops...) {
	@if is-direction($direction) == false {
	  $color-stops: ($direction, $color-stops);
	  $direction: 180deg;
	}
	
	background: nth(nth($color-stops, 1), 1);
	background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
	background: linear-gradient($direction, $color-stops);
  }
  
	@mixin showEllipsis($lineHeight, $linesToShow) {
		 /*! autoprefixer: ignore next */
		 -webkit-box-orient: vertical;
		 /*! autoprefixer: on */
		display:block;
		display: -webkit-box;
		max-width: 100%;
		height: $lineHeight*$linesToShow;
		margin: 0 auto;
		line-height:$lineHeight;
		-webkit-line-clamp: $linesToShow;
    	overflow: hidden;
		text-overflow: ellipsis;
  }

  @mixin multiLineEllipsisIE($lineHeight: 1.2em, $lineCount: 1, $mpr: 1em, $bgColor: white){
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount; 
	margin-right: -$mpr;
	padding-right: $mpr;
	&:before {
	  content: '...';
	  position: absolute;
	  right: 0;
	  bottom: 0;
	}
	&:after {
	  content: '';
	  position: absolute;
	  right: 0;
	  width: 1em;
	  height: 1em;
	  margin-top: 0.2em;
	  background: $bgColor;
	}
  }

  @mixin multiLineEllipsisIELtr($lineHeight: 1.2em, $lineCount: 1, $mpr: 1em, $bgColor: white){
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount; 
	margin-left: -$mpr;
	padding-left: $mpr;
	&:before {
	  content: '...';
	  position: absolute;
	  left: 0;
	  bottom: 0;
	}
	&:after {
	  content: '';
	  position: absolute;
	  left: 0;
	  width: 1em;
	  height: 1em;
	  margin-top: 0.2em;
	  background: $bgColor;
	}
  }
  
	@mixin primary-button-1() {
		@include font-style(HPSimplified, 16px, normal, 33px, $white);
		height: 33px;
		background-color: $hp-default-blue;
		outline: none;
		&:active {
			background-color: $hp-active-blue;
		}
		&:focus {
			box-shadow: 0 0 8px 0 $hp-blue;
			color: $white;
		}
		&:hover {
			background-color: $hp-hover-blue;
    	}
		&:disabled {
			background-color: $thunderstorm;
		}
  	}
  
	@mixin secondary-button-1() {
		background-color: $white;
		height: 33px;
		border: 1px solid $hp-default-blue;
		border-radius: 4px;
		@include font-style(HPSimplified, 16px, normal, 0px, $hp-default-blue);
		&:hover {
			color: $hp-hover-blue;
			border: 1px solid $hp-hover-blue;
			background-color: $white;
		}
		&:focus {
			color: $hp-default-blue;
			border : 1px solid  $hp-default-blue;
			box-shadow: 0 0 8px $hp-blue;
			outline: none;
			background-color: $white;
		}
		&:active {
			color: $hp-active-blue;
			border: 1px solid $hp-active-blue;
			background-color: $white;
		}
		&:disabled {
			border: 1px solid $thunderstorm;
			background-color: $white;
		}
  	}
  
	@mixin sign-in-link-style(){
		background-image: url(/wcc-assets/images/printer-signIn.svg);
		background-repeat: no-repeat;
		background-size: 25px 25px;
		background-position: 0px 8px;
		padding-left: 34px;
		min-height:40px;
		display:flex;
		align-items: center;
		//line-height: 40px;
		margin: 1px 0 0px 0;
  	}
  
	@mixin modal-common-style() {
		.modal-content {
			color: black;
			border-radius: 0px !important;
		}
		#close-icon {
			position: relative;
			top:7px;
			left: -5px;
			width:18px;
			height:18px;
		}	
		.modal-title {
			font-family: HPSimplified;
			margin-left: 20px;
			margin-top: 12px;
		}
		.modal-header {
			height: auto;
			min-height: 74px;
			padding: 12px;
			button.close {
			  font-family: 'HPControlIcons';
			  content: "\e607";
			  font-size: 35px;
			  color: $black;
			  &:hover {
				background-color: transparent;
			  }
			  &:focus {
				  span#close-icon{
					  border: 1px solid $brand-color;
				  }
			  }
			  }
			  
		   }
	}
	
  @mixin tooltip-modal-style-RTL(){
    .need-help-modal {
      &.rtl {
        .modal-header{
          &.help-modal-header{
            padding: 20px 20px!important;
            height: auto;
            .modal-title{
              position: relative!important;
              right:0px!important;
              padding:0px 20px 0 20px!important;
              flex:1;
            }
            button{
              position: relative;
              margin: 0px;
              float: none!important;
              left: 0px!important;
              &.no-modal-heading{
                flex: 1;
                margin: 0;
                text-align: left;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .need-help-modal {
        &.rtl {
          .modal-header{
            &.help-modal-header{
              .modal-title{
                padding:0px 0px 0 20px!important;
              }
            }
          }
        }
      }
    }
  }

  @mixin master-pfinder-component-style(){
		.pfinder-error-box{
			max-width:386px;
			width:68%;
			p.error-text {
				color: $error;
				font-size: 14px;
				font-family: HPSimplifiedRegular;
			}
		  }
		h3 {
			@include font-style(HPSimplifiedLight, 18px, 300, 1.44, $black);
			padding-bottom:5px;
		}
		.master-pfinder {
			display:flex;
			flex:1;
			p {
			  //2713 ALM
			  margin-bottom: 10px;
			  &.search-label{
				@include font-style(HPSimplifiedLight, 18px, 300, 1.44, $black);
			  }
			  label.field-help {
				@include font-style(HPSimplifiedLight, 18px, 300, 1.44, $black);
				font-weight: normal!important;
			  }
			  &.error-text {
				  margin-bottom:0px;
				  color: $error;
			  }
			}
			
			.search-box {
			  width:68%;
			  max-width:403px;
			  
			  .search-input {
				max-width:386px;
				//width:96%; 29thJune
				width:91%;
				position: relative;
				input.pfinder-input , input.add-device-input, input.pfinder-productnumber{
				  width: 100%;
				  height: 40px;
				  font-size: 16px;
				  padding: 0 36px 0 15px;
				  display: inline;
				  //Added for Firefox red color border
				}
				input#searchQueryField, input#productNumberField{
					&:invalid{
					 border: 1px solid $disable-color;
					}
				}
			  }
			  .pn-needed-error-text{
				  margin-top:5px;
				  margin-bottom:19px;
			  }
			}
			.button-box{
			  align-self: flex-end;
			  
			  button {
				//@include border-radius(0px);
				&:disabled {
				  background-color: $grey4;
				}
			  }
			}
			&.printerSetupLanding-pfinder{
				.search-box{
					.search-input{
						width:96%;
					}
				} 
				.button-box{
					align-self: flex-end;
					margin-left:13px;
				}
			}
		  }
		  .device-add-container {
			.master-pfinder{
			  .search-box{
				.search-input {
				  max-width:406px;
				  width:96%;
				}
			  }
			  .button-box {
				button {
					margin-left:13px;
				}
			  }
			} 
		}
		  .master-pfinder {
			&.rtl-pfinder {
			  .search-box {
				.search-input {
				  input.pfinder-input, input.add-device-input {
					padding: 0 15px 0 30px;
				  }
				  .close-icon {
					left:15px;
					right: unset;
				  }
				}
			  }
			}
		  }
		  @media only screen and (max-width: 800px){
			h3 {
				@include font-style(HPSimplifiedLight, 16px, 300, 1.44, $black);
				margin-top:19px;
				margin-bottom: 16px;
			}
			p.pfinder-search-label{
				margin-top:19px;
				margin-bottom: 16px;
			}
			.pfinder-error-box{
				width:100%;
			}
			.master-pfinder {
			  // 2713 ALM
			  margin-bottom:17px;
			  p {
				// 2713 ALM
				margin-top:19px;
				margin-bottom: 16px;
				label.field-help {
				  font-size:16px;
				}
			  } 
				.search-box {
				  width:100%;
				  max-width:100%;
				  .search-input  {
					width:100%;
					max-width:100%;
					input.pfinder-input, input.add-device-input {
					  width:100%;
					}
					input.form-control {
					  width:100%;
					}
				  }
				}
			  
			  .button-box{
				 height:33px;
				button {
				  margin-bottom: 0px;
				  word-break: break-all;
				padding: 5px 13px 5px 13px;
				border-radius: 0;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				-ms-border-radius: 0;
				//height: 35px;
				//min-height:35px;
				width:38px;
				background-image: url(/wcc-assets/images/u2635_a.svg);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 30px;
				span {
				  &.lg-only {
					display: none;
				  }
				}
			  }
			}
			&.printerSetupLanding-pfinder{
				.search-box{
					.search-input{
						width:100%;
					}
				} 
				.button-box{
					margin-left:0px;
				}
			}
		  }
		  .device-add-container {
			.master-pfinder{
			  .search-box{
				.search-input {
				  width:100%;
				}
			  }
			  .button-box {
				button {
					margin-left:0px;
				}
			  }
			} 
		}
		}
		// @-moz-document url-prefix() { 
		// 	input.pfinder-input , input.add-device-input, input.pfinder-productnumber{
		// 		@include required-input-style();
		// 	}
			
		// }
    }
    

    @mixin pfinder-error-content-style(){
		@include font-style(HPSimplifiedLight, 18px, normal, 1.25, $black);
		padding-bottom:63px;
		p {
			margin-bottom:3px;
			color: $black;
		}
		ul {
			padding-left:30px;
			li {
				list-style: disc;
				@include font-style(HPSimplifiedLight, 16px, normal, 1.25, $black);
				span {
					&.example-text {
						font-weight: bold;
					}
				}
				a {
					display: block;
					color: $blue ;
				}
			}
		}
		&.rtl-error-content {
			ul {
				padding-left:0px;
				padding-right:30px;
	
			}
		}
    }
    
    @mixin pfinder-sign-in-mob-style(){
		.sign-in-pfinder {
			margin-top:10px;
			border-top:1px solid $white5;
			margin-right:15px;
			.sign-in-exp{
				margin-top:10px;
			}
			&.rtl-sign-in-box{
				margin-right:0px;
			}
		}
    }
    
    @mixin rtl-pfinder-style(){
		#hp-support-com{
		  .printer-pfinder {
			&.rtl-pfinder {
			  #btnPSetLand-PfinderSubmit{
				-webkit-transform: rotateY(-180deg);
				-moz-transform: rotateY(-180deg);
				transform: rotateY(-180deg)
			  }
			  
			}
		  }
		}
      }
      
      @mixin carousel-arrow-style-ltr(){
        .swiper.s-wrapper {
            max-height:208px;
          }
          
          .swiper-button-prev {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            left:0px;
          }
          swiper-container >.swiper-slide {
            max-width:100%!important;
          }
    }
    @mixin carousel-arrow-style-rtl(){
        
          .swiper-button-prev {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
          .swiper-button-next {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            left:0px;
          }
          swiper-container > .swiper-slide {
            max-width:100%!important;
          }
    }

	@mixin center-align-content {
		display: flex;
		justify-content: center;
		align-items: center;
	}

