@import './variables.scss';

/* width */
::-webkit-scrollbar {
  width: 5px;
  background: $white;
  position: relative;
  left: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $cloud; 
  margin-top:10px;
  margin-bottom:10px;
  border-radius: 5px;
  margin-right: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  #707070;
  border-radius: 5px; 
  margin-right: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $medium-grey; 
}