/* You can add global styles to this file, and also import other style files */
@import 'wcc-assets/scss/variables.scss';
@import 'wcc-assets/scss/mixins.scss';
@import 'wcc-assets/scss/media-queries.scss';
@import 'swiper/scss';
// @import 'swiper/modules/pagination.scss';

@mixin swiper-arrow(){
  .swiper-button-prev, .swiper-button-next {
		background-image: url('/wcc-assets/images/arrow-dark-rignt-02.svg');
		background-size: 15px 30px;
	  }
}
// Fixing wrapping for Korean locales
html[lang^='ko']{
  word-break: keep-all;
} 
// Icon Dot style
.row-dot {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  display: inline-block;
  position: absolute;
}
.row-dot-blue {
  box-shadow:  0 3px 12px 0 rgba(0, 150, 214, 0.4);
  background-color: $brand-color;
}

// #R193-322 related fix
#hp-support-com .hp-header .mobile-nav-wrapper li ul li a:focus {
  outline: auto!important;
}
.loading-screen-wrapper {
  z-index: 100000;
  position: fixed;
  background-color: #FFF;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.90;
  top: 0px;
}

.loading-screen-icon {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  .loading-text {
    font-weight: 600;
    margin-top: 15px;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
#hp-support-com {
  background-color: #fff;
  margin: 0 auto;
  .header {
    .submenu {
      top: -126px;
      &:focus-visible {
        box-shadow: none;
      }
    }
    .background {
      top: 1px;
      height:75px!important;
    }
  }

  .hp-header {
    display: block;
    color: $black;
    background-color: transparent;
    margin: 0px;
    width: 100%;
    .hp-header-search {
      .mobile-search-toggle-close {
        line-height: 36px;
        float: right;
        display: block;

        &:hover {
          color: $brand-color;
        }

        &:focus {
          color: $brand-color;
        }
      }
      #clear-search {
        position: absolute;
        color: #ccc;
        bottom: 0;
        right: 15px;
        left: auto;
        top: 0;
        margin: auto;
        font-size: 28px;
        text-align: center;
        background-position: -2px -372px;
        background-repeat: no-repeat;
        overflow: hidden;
        display: block;
        width: 10px;
        height: 10px;
      }
    }
    .hp-row {
      position: relative;
    }
  }
}

#header {
  &.hide-search-typeahead {
    .autocomplete-wrapper.main {
      display: none !important;
    }
  }

  .skip-links {
    a.screenReading {
      padding: 0px;
    }
  }
}
h1.heading-text {
  margin-bottom: 17px;
  padding-top: 43px;
  text-align: center;
}

h2.resource-header {
  font-family: HPSimplifiedLight;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: .3rem;
  text-transform: uppercase;
}

h3.para-text {
  font-family: HPSimplifiedLight;
  font-size: 16px;
  line-height: 22px;
}

h3.serial-number-text {
  font-family: HPSimplifiedLight;
  font-size: 18px;
  line-height: 22px;
  color: $black;
}

.close-bg {
  button {
    &.close-alert {
      &:focus {
        background: none;
      }

      &:active {
        background: none;
      }
    }
  }
}
button#closeAlert{
    font-size:16px;
    display: block!important;
    height: inherit;
    font-weight: 400;
    top:10px;
    font-family: HPControlIcons;
  &:before{
    content:"\e607";
  }
}

/* modal start*/

.modal-header {
  justify-content: left;
}

.modal-footer{
  a{
    font-size: 14px;
    font-family: 'HPSimplified';
  }
}
/* modal ends*/

#hp-support-com {
  .hp-header {
    .mobile-nav-wrapper {
      li {
        ul {
          li {
            a.login-reqd-message {
              font-style: italic;
              font-size: 12px;
              color: $error;
              margin-bottom: 20px;
              cursor: text;
            }
          }
        }
      }
      ul {
        li {
          ul {
            li {
              display: block;
            }
          }
        }
      }
    }
  }
}

// Banner slider styles
.need-help-modal .swiper-button-next-unique, .need-help-modal .swiper-button-prev-unique {
  outline: none;
}
div:not(.swiper-button-prev, .swiper-button-next,.navbar-header-links) {
  outline: none!important;
}
 // For global Navigation
 .wps-tablet .wpr-header-tab .wpr-row .wpr-icons-holder .wpr-signin .wpr-signin-menu a:focus-visible{
   outline: revert;
 }

 .wps-tablet .wpr-header-tab .wpr-row .wpr-icons-holder .Rectangle-426 .tab-search {
  font-family: forma-djr-ui, forma-djr-micro, FormaDJRUI, 'Forma DJR UI', FormaDJRDisplay, 'Forma DJR Display',  Arial, sans-serif !important;
  font-weight: 400 !important;
 }

.wps-mobile .wpr-navbar-container .wpr-side-nav .Rectangle-515:focus-visible, .wps-mobile .wpr-signin-menu a:focus-visible {
     outline: revert !important;
   }
// Typeahead Style
.dropdown-item {
  font-family: HPSimplifiedLight,'Open Sans',sans-serif;
  font-size:16px;
  max-width: 100%;
  color:$black;
  font-weight: 300;
  padding: 5px 15px;
  height: auto;
  line-height: 1.35em;
  border-bottom: 1px solid $white4;
  white-space: pre-wrap;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
}
.dropdown-item.active {
  background-color: #F0F9FF;
  color: $hp-hover-blue;
}
.dropdown-menu {
  border: 1px solid $white4;
  margin: 0;
  padding: 0;
  font-family: HPSimplified;
  @include border-radius(0px);
}
.onebox-container {
  .dropdown-menu {
    border: 1px solid $white4;
    margin: 0;
    padding: 0;
    @include border-radius(0px);
    width:425px;
}
}
.rtl{
  .onebox-container {
    .dropdown-menu{
      right: auto;
    }
  }
}
.search-product {
  .dropdown-menu {
   max-width:100%;
  }
} 
.product-finder {
  .close-icon {
    //top:1px;
    top:7px;
    right: 15px;
    position: absolute;
    padding:2px;
    &:focus{
      text-decoration: none;
    }
    &:hover{
      text-decoration: none;
    }
  }
 
  .form-control {
    &.pfinder-input{
      padding-right:30px;
      font-family: HPSimplifiedLight;
    }
  }
  .dropdown-menu {
    top: 45px;
    width:100%;
    border-radius: 4px;
    box-shadow: 0px 4px 6px $thunderstorm;
  }
 }

 .knowledgeSearchContainer{
  a.placeHolderLink{
      text-align: left;
      position: relative; 
      top:-30px;
      left:15px;
      color:#ccc;
      text-decoration: underline;
      width: fit-content;
  }
  .close-icon{
      &.hide {
          display:none!important;
      }
  }
}

.knowledgeSearchButton{
 position: relative;
 width: 45px!important;
 left: -5px;
 text-align: center;
 padding:0px;
 img {
   width:25px;
   height:25px;
   max-width:25px;
 }
}

input.knowledge-input{
 width: 425px;
 height: 45px;
 font-size: 16px;
 padding: 0 30px 0 15px !important;
 display: inline;
 font-family: HPSimplifiedLight;
}

 .onebox-group{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .vjs-control-bar {
    .vjs-control {
      .vjs-icon-placeholder{
        &:before {
          top:0px!important;
        }
      }
      &.vjs-button {
        .vjs-icon-placeholder{
          &:before {
            top:0px!important;
          }
        }
      }
    }
  }
  
@media only screen and (max-width: 800px) {
  .add-new-device-modal {
    .modal-dialog {
      margin: 20px !important;
    }
  }

  @include rtl-pfinder-style();
  #hp-support-com {
    .printer-pfinder {
      button#btnPSetLand-PfinderSubmit{
        min-height: 35px;
        word-break: break-all;
        padding: 5px 13px 5px 13px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        height: 35px;
        width:38px;
        background-image: url(/wcc-assets/images/u2635_a.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30px;
        span {
          &.lg-only {
            display: none;
          }
      }
     
    }
    }
  }
}

 @media only screen and  (max-width: 767px) {
  .bannerSlider.image-slider{
      height: 185px!important;
      
      img {
          width: 80%;
          height: 155px;
          position: relative!important;
      }
    
      .swiper-slide{
        width: 100%!important;
      }
    
      .swiper-slide .carousel-caption{
          left: 5%;
          top: 7%
      }    
  }
  
  .printer-carousel.bannerSlider.image-slider{
      img {
        height:auto !important;
        max-height: 170px;
      }
    }
  .onebox-container{
    .close-icon {
        margin-left: -30px!important;
    }
    input[type="text"].knowledge-input{
        width: 75%;
        border: 1px solid #ced4da;
        padding: .6rem 30px .6rem 15px;
    }
    a.placeHolderLink{
        left: 10%;
    }
    .dropdown-menu {
        width:75%;
    }
    }
    .product-finder{
      .master-pfinder{
        &.printer-pfinder{
          .dropdown-menu {
            top: 0px !important;
            width:95%;
          }
        }
        .dropdown-menu {
          top: 0px !important;
          width:100%;
        }
      }
    }
    
.product-finder {
    .close-icon {
        right: 7px;
    }
    input[type="text"].form-control {
        &.pfinder-input {
            padding-right: 27px;
            font-family: HPSimplifiedLight;
        }
    }
  }
}
.printer-pfinder {
  .search-box {
    .search-input  {
      .dropdown-menu {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .pfinder-carousel-basic{
    .carouselSlider{
      position: relative;
    }
  }
  .swiper-pagination-handle{
    padding: 4px 13px 4px 4px!important;
  }
  .section-wrapper .bannerSlider.image-slider{
    height: 225px;
    
    img {
        width: 100%!important;
        height: 190px;
        position: relative!important;
    }
    .swiper-slide{
      width: 100%!important;
    }
    .swiper-slide .carousel-caption{
        left: 5%;
        top: 0%
    } 
  }
}

@media only screen and  (max-width: 767px) {
  .carouselSlider {
    margin: 0 auto;
    width: 74.8%;
  }
}

@media only screen and (min-width: 768px) {
  .carouselSlider{
    margin: 0 auto;
    width: 75%;
  }
}

.slider-wrapper {
  margin: 0 auto;
  width: 75% !important;
}

.common-content-find-more {
  button.btn {
  padding-left: 30px;
  padding-right: 30px;
  }
} 

@media (min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}

@media only screen and (min-width: 801px),
print and (min-width: 4in) {
  #hp-support-com .hp-header .mobile-nav-wrapper li {
    border: none;
    display: inline-block;
    max-width: 20%;
    min-height: 28px;
  }

  .customDropdown .mobile-nav-wrapper .container > ul > li:not(:last-child) {
    margin-right: 23px !important;
  }
}

// Banner Css
.borderBottom {
  border-bottom: 1px solid #dee2e6;
}

.detect-device-container ul.search-list-container li.search-option a span.highlightText {
  color: $black;
}

.detect-device-container ul.search-list-container li.search-option a span.highlightText:hover {
  color: $brand-color;
}

app-video-player{
  div.video-js{
    position: absolute;
  }
}

.video-js {
  .vjs-big-play-button {
    .vjs-icon-placeholder {
      &:before {
        top: 0 em;
      }
    }
  }
  .vjs-modal-dialog {
    top: 45px;
  }
}

.vjs-button {
  .vjs-icon-placeholder {
    &:before {
      top: 45px;
    }
  }
}

.app-solution-video-window{
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    top:0px!important;
  }
}

//Printer setup landing carousel
// .carousel-container {
//   img {
//     max-width:362px;
//     width: 100%;
//   }
// }
.printer-carousel {
  @include carousel-arrow-style-ltr();
  @include swiper-arrow();
}
.rtl-printer-carousel {
  .printer-carousel {
    
    @include carousel-arrow-style-rtl();
    @include swiper-arrow();
  }
}
.tooltip-carousel {
  @include carousel-arrow-style-ltr();
  @include swiper-arrow();
}
.rtl-tooltip-carousel {
  .tooltip-carousel {
    @include carousel-arrow-style-rtl();
    @include swiper-arrow();
  }
}

.vjs-modal-dialog {
  .vjs-modal-dialog-content {
    top: 45px;
  }
}
.error-modal-container .modal-lg {
  max-width: 500px;
  width: 500px;
  margin: 60px auto;
}
@media (min-width: 1200px) {
  .section-wrapper {
    width: 1000px;
    margin: 0 auto;
  }
}

@media only screen and (min-width:768px) {
  .hidden-lg,
  .show-mobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hidden-mobile{
      display: none;
    }
  .header-bg{
    h1.heading-text {
      font-size: 22px;
      line-height: 24px;
      margin-top: 25px;
      margin-bottom: 10px; 
    }
  }
  h2.resource-header {
    font-family: HPSimplifiedLight;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .promo-tile {
    h2 {
      font-size: 18px;
    }
  }
  h3.para-text {
    font-family: HPSimplifiedLight;
    font-size: 18px;
    line-height: 20px;
  }
  .detect-device-container {
    .border-left {
      border-left: none !important;
    }
  }
  .mobile-bg {
    background:$white2;
  }
  .modal-dialog {
    margin:10px !important;
    .modal-content {
      width: 100%;
    }
  }
  .country-selector .modal-dialog .modal-content {
    width:98%;
    margin:0 auto;
  }
  span.clc-header {
    font-size: 14px;
  }

  .select-model-modal {
    .modal-dialog {
      margin: 0 !important;
    }
  }
  .select-country-modal {
    .modal-dialog {
      margin: 0 !important;
    }
  }
}

.notifications-main .notification-body a {
  font-size: 14px !important;
}

.notifications-main .notification-body p {
  font-size: 14px !important;
  line-height: 18px;
  margin: 0;
  font-family: HPSimplified !important;
  color: #767676;
}

.knowledgeSearchContainer{
  li.search-option{
    border-bottom: 1px solid $white4;
    font-size: 14px;
  }
  ul.search-list-container{
    border: 1px solid $white4;
    margin: 0;
    padding: 0;
  }
  
  ul.search-list-container a{
      color: #aeaeae;
      padding: 5px 15px;
      display: block;
  }
  span.highlightText{
    font-weight: bold;
    color:#000;
  }
}
//Error scenario Style
.service-unavailable-error-container {
  padding-top: 20px;
  padding-top: 50px;
  margin: 0 auto;
  max-width: 950px;
  padding-bottom: 100px;
  .error-info {
    background-image: url('/wcc-assets/images/System_Information 120.svg');
    background-repeat: no-repeat;
    background-position: left 32px;
    padding-left: 248px;
    background-size: 155px 155px;
    h1 {
      font-family: HPSimplified;
      line-height: 1.09;
    }
    p {
      max-width : 683px;
      font-family: HPSimplifiedLight;
      font-size: 27px;
      line-height: 1.33;
      color: $black;
      padding-top:6px;
    }
    div {
      &.helpful-link {
        padding-top: 57px;
        p{
          font-size: 20px;
          font-family: HPSimplified;
          line-height: 1.4;
          margin-bottom:0px;
        }
        ul {
          li {
            &:first-child {
              padding-top: 8px;
            }
            padding-top:14px;
          }
        }
      }
    }
  }

  &.icon-hidden {
    padding-top: 0;
    padding-bottom: 0;
    .error-info {
      text-align: center;
      padding-left: 0;
      background-image: none;

      h1 {
        margin-bottom: 20px;
      }

      .refresh-btn-container {
        margin-top: 40px;
        margin-bottom: 50px;

        a {
          width: 200px;
        }
      }

      p {
        margin: 0;
        font-size: 20px;
        padding: 0;
        max-width: none;
      }

      div {
        &.helpful-link {
          padding-top: 0;

          p {
            font-family: HPSimplifiedLight;
            max-width: none;
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 30px;
          }

          ul {
            li {
              &:first-child {
                padding-top: 0;
              }
              padding-top:12px;
            }
          }
        }
      }
    }
  }
}
.error-message {
  .service-unavailable-error-container {
    .error-info {
      background-position: left 22px;
    }
  }
}

//Service unavailable --mobile
@media only screen and (max-width: 768px) {
  h1.heading-text {
    padding-top: 0;
  }
  .service-unavailable-error-container {
    padding:  65px 23px 50px 23px;
    .error-info {
      background-image: url('/wcc-assets/images/System_Information 120.svg');
      background-repeat: no-repeat;
      background-position: top center!important;
      padding-left: 0px;
      background-size: 112px 112px;
      padding-top: 135px!important;
      h1 {
        font-family: HPSimplified;
        line-height: 1.18;
        font-size: 22px;
      }
      p {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 34px;
      }
      div {
        &.helpful-link {
          padding-top: 74px;
          p{
            line-height: 28px;
          }
        }
      }
    }
  }
  .device-card-error {
    .service-unavailable-error-container {
      padding-top: 61px;
      padding-bottom: 95px;
    }
  }
}
//Error Session timeout error Style
.session-timeout-error-container {
  padding-top: 32px;
  margin: 0 auto;
  padding-bottom:225px;
  .error-info {
    background-image: url('/wcc-assets/images/session_timeout.svg');
    background-repeat: no-repeat;
    background-position: left 29px;
    padding-left: 238px;
    background-size: 155px 155px;
    h1 {
      font-family: HPSimplified;
      line-height: 1.09;
    }
    p {
      max-width : 587px;
      font-family: HPSimplifiedLight;
      font-size: 27px;
      line-height: 1.33;
      color: $black;
      padding-top:6px;
      margin-bottom: 28px;
    }
    a {
      &.skipLink {
        margin-left: 18px;
        span{
          font-family: HPSimplified;
        }
      }
    }
    div {
      &.helpful-link {
        padding-top: 76px;
        p{
          font-size: 20px;
          font-family: HPSimplified;
          line-height: 1.4;
          margin-bottom:0px;
        }
        
        ul {
          li {
            &:first-child {
              padding-top: 8px;
            }
            padding-top:14px;
            a {
              padding-left:0px;
              padding-right:0px;
            }
          }
        }
      }
    }
  }
}
//Session timeout error --mobile
@media only screen and (max-width: 768px) {
  .session-timeout-error-container {
    padding-top: 67px;
    width: 90%;
    padding-bottom:95px;
    .error-info {
      background-position: top center;
      padding-left: 0px;
      background-size: 112px 112px;
      padding-top: 135px;
      h1 {
        font-family: HPSimplified;
        line-height: 1.18;
        font-size: 22px;
      }
      p {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 34px;
      }
      a {
        &.skipLink {
          display: block;
          margin-left: 0px;
          margin-top:20px;
        }
      }
      div {
        &.helpful-link {
          padding-top: 74px;
          p{
            line-height: 28px;
          }
        }
      }
    }
  }
}
// Error Modal Related CSS
.error-modal-container {
  .modal-lg {
    max-width: 500px;
    width: 500px;
    margin: 60px auto;
    .error-modal {
      .modal-header {
          padding: 15px 25px 15px 20px !important;
            h3 {
              margin-left: 20px;
              line-height: 50px;
              font-family: HPSimplifiedLight;
              font-size: 20px;
            }
              button {
                  span {
                      height: 40px;
                      font-family: HPControlIcons;
                      content: "\e607";
                      position: relative;
                      top: 15px;
                      right: 15px;
                      font-size: 30px;
                      color: #767676;
                  }
              }
      }
      &.rtl {
        .modal-header {
            button {
                span {
                    left: 30px;
                    right:0px;
                }
            }
    }
      }
      &.service-unavialable {
        .modal-header {
          button {
            padding:0px;
            margin-top:5px;
            margin-right: 0;
            span {
              top:0px;
              right:0px;
            }
            &:focus {
              outline: 1px solid $blue1 !important;
              //box-shadow: 0 0 8px 0 rgba(0, 150, 214, 0.9);
            }
          }
        }
      }
    }

  }
  .modal-body {
    padding: 44px 44px 62px;
    p{
      height: 60px;
      font-size: 16px;
      line-height: 1.18;
      color: $black;
      margin-bottom: 0px;
    }
    .close-button{
      text-align: right;
      font-size: 16px;
      button {
        font-size: 16px;
        height: 40px;
      }
    }
  }
}
.conflict-modal-container {
  .modal-lg {
    max-width: 1010px;
    width: 90%;
    margin: 60px auto;
   .modal-content {
    border-radius: unset;
   }

  }
  .modal-body {
    padding: 44px 44px 62px;
    p{
      height: 60px;
      font-size: 16px;
      line-height: 1.18;
      color: $black;
      margin-bottom: 0px;
    }
    .close-button{
      text-align: right;
      font-size: 16px;
      button {
        font-size: 16px;
        height: 40px;
      }
    }
  }
}
@media only screen and (min-width: 800px) { 
  .re-add-modal {
    .modal-sm {
      width: 800px;
      max-width: 800px;
      margin: 60px auto;
    }
    
  }
  .select-model-modal {
    .modal-sm {
      width: 800px;
      max-width: 800px;
      margin: 120px auto;
    }
  }
  .select-country-modal, .repair-replacement-modal {
    .modal-sm {
      width: 600px;
      margin: 120px auto;
    }
 }

}
@media only screen and (max-width: 768px) {
  #hp-support-com .hp-header .customDropdown.open .dropdown-menu a {
    padding:0px;
  }
  
  .error-modal-container {
    .modal-lg {
      max-width: 90%;
      width: 90%;
      margin: 20px auto!important;
    }

    .modal-body {
      padding: 30px 26px 28px;
    }

    .modal-lg {
      .error-modal {
        .modal-header {
          padding: 38px 26px 38px !important;

          h3 {
            margin-left: 0;
            line-height: inherit;
          }
        }

        &.service-unavialable {
          .modal-header {
            button {
              margin-top: -10px;
            }
          }
        }
      }
    }
  }
  .light-theme {
    .conflict-modal-container  {
      .modal-lg {
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: auto!important;
      }
      .modal-dialog {
        margin: auto!important;
      }
  
      .modal-body {
        padding: 30px 26px 28px;
      }
    }
  }

  .conflict-modal-container  {
    .modal-lg {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: auto!important;
    }
    .modal-dialog {
      margin: auto!important;
    }

    .modal-body {
      padding: 30px 26px 28px;
    }
  }
  .select-country-modal {
    .modal-sm {
      height: 100%;
      .modal-content {
        height: 100%;
      }
    }
 }
}

.modal-dialog {
  width: auto;
  margin: 10px;
  max-width: 100%;
}
.case-detail-modal, .case-list-modal {
  .modal-lg {
    max-width: 1200px;
    width:96%;
  }
}
.requestToClose-modal {
  .modal-lg {
    max-width: 800px;
  }
}
.retired-product-modal {
  .modal-dialog {
    max-width: 1200px;
  }
}
.cd-timeline__content {
  button.btn {
    width:100%!important;
    padding:0px;
    padding-left: 43px;
  }
 
  button.btn-link {
    .fa-angle-down:before {
      content: ''
    }
  }
  button.btn-link.collapsed {
    .fa-angle-down:before {
      content: ''
    }
  }
  .card {
    border: none;
    background: transparent url(/wcc-assets/images/blue-circle.png) no-repeat;
    background-position: left 20px;
  }
  .card-header {
    padding: 0;
    border-bottom: none;
    background: none;
    &:first-child {
      border-radius: 0px;
    }
  }
  .card {
    border-radius: 0px;
  }
}
.case-detail-modal, .case-list-modal {
  .modal-dialog {
    max-width: 1200px;
    width: 96%;
    margin: 1.75em auto!important;
  }
}
.profile-modal {
  .modal-dialog {
    max-width: 800px;
    width: 96%;
    margin: 1.75em auto!important;
  }
}
@media only screen and (min-width: 801px) {
  .error-scenario-modal {
    @include modal-header-style-case();
    .modal-dialog {
      max-width: 500px;
      width: 500px;
      h3#modal-heading {
        margin-top: 11px;
        font-size: 20px;
      }
    }
    .modal-body {
        padding: 40px;
        p{
          height: 60px;
          font-size: 22px;
          line-height: 26px;
          font-family: HPSimplifiedLight;
        }
        .close-button {
          text-align: right;
        }
    }
  }
}
@media only screen and (min-width: 801px), print and (min-width: 4in) {
  .modal-lg {
      max-width: 900px;
      width: 95%;
      margin: 60px auto;
  }
  .add-new-device-modal {
    .modal-dialog {
      margin: 60px auto !important;
      max-width: 1200px;
    }
  }
}


// Browser Specific styles
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .knowledgeSearchButton{
      left: -7px;
      top: 0px !important;
  }
  .nonTablet .detect-device-container.section-wrapper{
      background-size: 200px auto;
      background-position: -6% 14px !important;
  }
  .onebox-group .close-icon{
    position: relative;
  }
}
@media screen and (max-width: 1200px) and (min-width: 992px){
  #hp-support-com {
    .dashboard-content-section {
      .tabContent {
        .container {
          max-width: 100%!important;
          .my-devices-content {
            .container {
              .nopadding {
                padding-left: 0px!important;
                padding-right: 0px!important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) and (min-width: 750px ){
  #hp-support-com {
    .dashboard-content-section {
      .tabContent {
        .container {
          max-width: 100%!important;
          .my-devices-content {
            .container {
              .nopadding {
                padding-left: 0px!important;
                padding-right: 0px!important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 990px) and (min-width: 750px ){
  .device-table-header {
    .td {
    .icon-arr {
      &.up-arrow {
      top : 5px;
      }
    }
    }
}
}
@media (min-width: 576px) {
  .dashboard {
      .container {
        max-width: 100%;
      }
  }
}
@media (min-width: 768px) {
  .dashboard {
      .container {
        max-width: 100%;
      }
  }
}

// video modal styles
.app-modal-window {

  .modal-header{
    padding: 0px !important;
  }
  .modal-dialog{
      border: 1px solid grey;
      box-sizing: border-box;
      p{
        color: #2c3038;
      }
  }
  .modal-footer{
    p{
      color: #2c3038 !important;
    }
  }
  
}

@media screen and (min-width: 992px) {
.app-modal-window {
  .modal-dialog {
    width: 800px; /* New width for default modal */
  }
  .modal-header{
      height: 450px !important;
  }
  .modal-body {
      padding-left: 37px;
      .video-title {
          p{
              font-size: 20px;
              color: #2c3038;
          }
       }
       p{
           font-size: 16px;
           color: #2c3038;
       }
  }
  .modal-footer{
      padding-left: 37px;
      p{
        color: #2c3038;
      }
  }
}
}
@media screen  and (max-width: 992px) {
  .app-modal-window {
      .modal-dialog {
        margin: 15px auto !important;
      }
      .modal-body {
           padding-left: 15px;
           .video-title {
              p{
                  font-size: 14px;
              }
              div{
                justify-content: flex-end !important;
              }
           }
           p{
              font-size: 12px;
              font-family: HPSimplified;
           }
      }
      .modal-footer{
          padding-left: 15px;
          .video-feedback{
            justify-content: flex-end !important;
          }
      }
      p{
          font-size: 12px;
      }
  }
  }
  @media screen and (max-width: 768px) {
      .app-modal-window {
          .modal-dialog {
            margin: 15px auto !important;
          }
          .modal-body {
               padding-left: 15px;
               .video-title {
                  p{
                      font-size: 14px;
                  }
                  div{
                    justify-content: flex-end !important;
                  }
               }
               p{
                  font-size: 12px;
               }
          }
          .modal-footer{
              padding-left: 15px;
              .video-feedback{
                justify-content: flex-end !important;
              }
          }
          p{
              font-size: 12px;
          }
      }
      }

.footer_lang_container {
  display: none;
}

a[name='skiptofooter'] {
  display: none;
}

.add-new-device-modal {
  .bannerSlider {
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 5px;
    }
  }
}

@media screen and (max-width: 1080px) and (min-width: 320px) {
  .header .hdr_buttons li a  {
    padding:0px;
  }
  .header .hp_logo a.hf_logo{
    padding:0px;
  }
}

@media only screen and (min-width: 497px) and (max-width: 800px),
only screen and (max-width: 496px) {
  #hp-support-com {
    .hp-header {
      .customDropdown {
        .mobile-nav-wrapper {
          .container {
            a {
              &:hover {
                text-decoration: none;
              }
              &:active {
                text-decoration: none;
              }
              &:focus{
                text-decoration: none;
              }
            }
          }
        }
        &.open .dropdown-menu li a{
          &:hover {
            text-decoration: none;
          }
          &:active {
            text-decoration: none;
          }
        }
        .dropdown-toggle {
          &:hover {
            text-decoration: none;
          }
          &:active {
            text-decoration: none;
          }
          &:focus{
            text-decoration: none;
          }
        }
      }
    }
  }
}


// Device Carousel CSS
.bannerSlider.device-slider {
  .swiper-button-prev, .swiper-button-next {
    background-image: url('/wcc-assets/images/arrow-dark-rignt-02.svg');
    background-size: 15px 30px;
  }
  .swiper-button-prev {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left:0px;
  }
  swiper-container > .swiper-slide {
    max-width:100%!important;
  }
}

.rtl-device-card-carousel {
    .bannerSlider.device-slider{
      .swiper-button-prev, .swiper-button-next {
        background-image: url('/wcc-assets/images/arrow-dark-rignt-02.svg');
        background-size: 15px 30px;
      }
      .swiper-button-prev {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
      .swiper-button-next {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        left:0px;
      }
      .swiper-container >.swiper-slide {
        max-width:100%!important;
      }
    }    
}

.device-slider.mobile {
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 5px;
  }
}
//Solution page Step Slider
.bulleted-pagination.numbered-pagination .swiper-pagination-bullet{
  background: #f3f3f3!important;
}
.bulleted-pagination.numbered-pagination .swiper-pagination-bullet-active {
  background: #0096D6!important;
}
#solution-step-video{
    .card-container {
      display:flex;
    }
    .solution-step-video-slider{
        height: 185px!important;
        img {
            width: 80%;
            height: 155px;
            position: relative!important;
        }
        .swiper-slide .carousel-caption{
            left: 5%;
            top: 7%
        } 
    }
    .printer-carousel.bannerSlider.image-slider{
        img {
          height:auto !important;
          max-height: 170px;
        }
      }
    .onebox-container{
      .knowledgeSearchButton{
          top:-3px;
      }
      .close-icon {
          margin-left: -30px!important;
      }
      input[type="text"].knowledge-input{
          width: 75%;
          border: 1px solid #ced4da;
          padding: .6rem 30px .6rem 15px;
          font-family: HPSimplifiedLight;
      }
      a.placeHolderLink{
          left: 10%;
      }
      .dropdown-menu {
          width:75%;
      }
      }
      .product-finder{
        .master-pfinder{
          &.printer-pfinder{
            .dropdown-menu {
              top: 0px !important;
              width:95%;
            }
          }
          .dropdown-menu {
            top: 0px !important;
            width:100%;
          }
        }
      }
      
  .product-finder {
      .close-icon {
          right: 7px;
      }
      input[type="text"].form-control {
          &.pfinder-input {
              padding-right: 27px;
              font-family: HPSimplifiedLight;
          }
      }
  }
}
#solution-step-video {
  .solution-step-video-slider{
    height:100px!important;
    .video-card-slider{
      .card-container {
        .thumbnail-container{
          width:141px;
          height:80px;
          margin-right:18px;
            .linkThumbnail{
              margin-left:0px;
              display:block;
              padding:0px;
              position:relative;
              img {
                height:80px!important;
                width:100%;
              }
            }
        }
      }
    }
  }
}
.tier-solution-box {
  .swiper-slider{
    a {
      display: block;
      text-decoration: none;
      max-width: 141px;
      line-height: 1.2;
      font-size: 16px;
      padding: 0px;
    }
  }
  .play-button{
    position: absolute;
    top: 43px;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-image: url(/wcc-assets/images/play-btn-thumb-light.svg);
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 12px 24px;
    cursor: pointer;
  }
}

@media only screen and (max-width:768px) {
  #solution-step-video {
    .solution-step-video-slider {
      .video-card-slider {
        .card-container{
          .thumbnail-container {
              a {
                img {
                  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.1);
                }
              }
          }
          .details-container {
            max-width:47%;
            min-width:47%;
            text-align:left;
            a{
              word-wrap: break-word;
            }
          }
        } 
      }
    }
  }
  #solution-step-video.rtl  {
    .solution-step-video-slider {
      .video-card-slider {
        .card-container{
          .thumbnail-container {
            margin-left:18px;
            margin-right:0px;
          }
          .details-container {
            text-align:right;
          }
        } 
      }
    }
  }
}
    
.videoSlider {
  position: relative;
  .swiper-custom-pagination {
    .swiper-pagination-bullet{
      width: 11px;
      height: 11px;
      margin: 10px;
      &:focus {
        outline: rgba(0,150,214,1) auto 1px;
      }
      .swiper-pagination-bullet-active {
        &:focus {
          outline: rgba(0,150,214,.2) auto 1px;
        }
      }
    }     
  }
 
  .swiper-button-prev-video {
      left: -38px;
      right: auto;
      top:65px;
      background-image: url(/wcc-assets/images/arrow_icon_blue_nocircle.svg);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      background-repeat: no-repeat;
      background-position-y: center;
      position: absolute;
      z-index: 10;
      cursor: pointer;
      &:focus {
        outline-color: rgba(0, 150, 214, 0.2);
      }
  } 
  .swiper-button-next-video {
      right: -38px;
      left: auto;      
      top:65px;
      background-image: url(/wcc-assets/images/arrow_icon_blue_nocircle.svg);
      background-repeat: no-repeat;
      background-position-y: center;
      position: absolute;
      z-index: 10;
      cursor: pointer;
      &:focus {
        outline-color: rgba(0, 150, 214, 0.2);
      }
  }
}

@media only screen and (max-width: 480px) {
  .mobVideoSlider {
    .swiper-custom-pagination {
      margin-top: -42px !important;
    }
  }
}
  .mobVideoSlider {
    .swiper-custom-pagination {
      margin-top: -83px;
      .swiper-pagination-bullet{
        margin-top: -90px;
        position: relative;
        z-index: 1;        
        width: 10px;
        height: 10px;
        margin: 5px;
        outline:none;
      }  
    }    
  }

#brightcove_id{
  &.video-js {
      button.vjs-big-play-button .vjs-icon-placeholder:before {
          top: 1px; 
     }
  }
}
 img {
      max-width: 100%;
    }

    .feedbackSectionContainer{
    display: flex;
    justify-content: center;
    &>div{
      width: 800px;
    }
  }
  .detect-device-wrapper{
    width: 100%;
    background-color:$white2;
    margin: 30px 0 28px 0;
    padding-left: 20px;
    h2 {
      margin-bottom:0px;
    }
    &.siteHome .detect-device-container {
      background-image: url(/wcc-assets/images/identify_product_all.svg);
  
  }
  &.computerLanding .detect-device-container {
      background-image: url(/wcc-assets/images/identify_product_laptop.svg);
  }
  &.printerLanding .detect-device-container {
      background-image: url(/wcc-assets/images/identify_product_printer.svg);
  }
  &.productLanding .detect-device-container {
    background-image: none;
    padding-left: 0px;
  }
  &.productLanding {
    background-color: #FFFFFF;
  }
    
    .detect-device-container{
      padding: 25px 0 0 120px;
      background-repeat: no-repeat;
      background-size: 90px auto;
      background-position: 0px 15px;
    }
  }
  @media (min-width: 1200px){
    .detect-device-container{
      .container.no-padding {
        padding-left:0px;
        padding-right:0px;
     }
    }
  }
  @media (min-width: 768px) {
    .detect-device-container{
      .container.no-padding {
        padding-left:0px;
     }
    }
  }
  @media only screen and (max-width: 800px) {
    .sign-in-pfinder {
      margin-top: 10px;
      border-top: 1px solid $white5;
      margin-right: 15px;
      &.register-box{
        border-top: none;
      }
    }
    .detect-device-wrapper{
      .detect-device-container{
        .container.no-padding {
          padding-left:0px;
       }
      }
      &.siteHome .detect-device-container {
        background-image: none;
        @include pfinder-sign-in-mob-style();
    }
    &.computerLanding .detect-device-container {
        background-image: none;
        @include pfinder-sign-in-mob-style();
    }
    &.printerLanding .detect-device-container {
        background-image:none;
        @include pfinder-sign-in-mob-style();
    }
    }
  }
  @media not all and (pointer: coarse) {
    .special-text:hover .special-text__tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
  
  @media all and (-ms-high-contrast:none){
    *::-ms-backdrop, .knowledgeSearchBoxContainer  a.close-icon {
      top: 2px;
      font-size: 20px;
      right: 10px;
      position:relative!important;
    }
    *::-ms-backdrop, input.knowledge-input {
      padding:0 38px 0 15px !important;
    }
  } 
    
  @media only screen and (min-width: 769px) {
    .visibilityMob {
        display: none;
    }
    .visibilityLg {
        display: block;
    }
  }
  @media only screen and (max-width: 768px) {
    .visibilityMob {
        display: block;
    }
    .visibilityLg {
        display: none!important;
    }
  }
  .error-text{
      @include font-style(HPSimplified, 14px, normal, 1.43, $redDot);
  }
  .hidden {
    display: none;
    visibility: hidden;
}

.grecaptcha-badge{
  visibility: hidden;
}

@media only screen and (min-width: 769px){
  .solution-template-container{
     .dashboard-services{
         .resource-option {
             margin-bottom: 0px!important;
         }
     } 
  }
}

// window resize CSS 
@media only screen and (max-width: 767px) {
  .mob-no-border-left {
    border-left: none;
  }
  
  #hp-support-com {
 
    .find-more-section{
      display:none;
    }
    .device-detector-box {
      display:none;
    }
    #psf {
      display: none;
    }
    .common-content-find-more{
      display: none;
    }
    .notifications-main {
      display:none;
    }
    .search-submit {
      padding: 0 25px;
      background-image: url('/wcc-assets/images/u2635_a.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px;
      background-color: $hp-default-blue;
      &:disabled {
        background-color: $thunderstorm;
      }
    }
    #product-submit-button button:enabled {
      background-color:$blue;
    }
  }
}

.conversion-popup{
  .modal-dialog{
     max-width: 500px;
     top: 50%;
     margin: auto;
     transform: translateY(-50%) !important;
 }
}

.conversion-loader-modal{
 z-index: 1000000000 !important;
}

.opt_feedback_li {
	cursor: pointer; 
	transform: rotate(90deg); 
	width: 42px;
} 
.opt_feedback_link { 
	color: $hp-blue-web-acc !important; 
	padding: 13px 20px !important; 
	height: 42px; 
	display: inline-block !important; 
	margin-bottom: 0px !important; 
	white-space: nowrap !important; 
	background: $gray-95;
	transform: rotate(180deg);
  border: 1px solid $thunderstorm;
} 
.opt_feedback_li a:hover{ 
	text-decoration: none;
} 
.opt_feedback_link a:hover{ 
	text-decoration: none;
} 
#psf li a.opt_feedback_link:focus { 
	outline: auto $notification-link-fcus-color; 
	outline-offset: 1px; 
	text-decoration: none;
} 
//Overwrite header CSS
#header {
  span#signin-profile-settings  a.link_metrics{
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px!important;
      box-shadow: none!important;
      div.wpr-signin-menu-item{
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px!important;
          box-shadow: none!important;
        }
        &:focus {
          outline: -webkit-focus-ring-color auto 1px!important;
          box-shadow: none!important;
        }
      }
    }
    &:focus {
      outline: -webkit-focus-ring-color auto 1px!important;
      box-shadow: none!important;
      div.wpr-signin-menu-item{
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px!important;
          box-shadow: none!important;
        }
        &:focus {
          outline: -webkit-focus-ring-color auto 1px!important;
          box-shadow: none!important;
        }
      }
    }
  }
  
}

/*Omni channel chat related css*/
.omni-channel-modal {
  .modal-dialog{
      .modal-content{
          width: 801px!important;
          margin:0 auto;
          border-radius: 0;
          border-color: #fff;
      }
  }
}
/*LMI CHAT MODAL Related*/
.lmi-chat-modal{
  .modal-dialog{
    .modal-content{
        width: 801px!important;
        margin:0 auto;
        border-radius: 0;
        border-color: #fff;
    }
  }
}
.lmi-chat-close-modal{
  .modal-dialog{
    .modal-content{
      width:478px;
      margin:0 auto;
      border-radius: 0;
      border-color: #fff;
    }
}
}
@media screen and (min-width: 320px) and (max-width: 768px) { 
  .omni-channel-modal .modal-dialog .modal-content{
      width: 100% !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) { 
  .omni-channel-modal .modal-dialog .modal-content{
      width: 720px !important;
  }
}


/*DCC landing page dropdown css*/
.dcc-card .hp-dropdown{
  width: 100%;
  .menu{
      width: 100%;
      max-width: 263px;
      margin-left: auto;
      margin-right: auto;
  }
}
.rtl .dcc-card .hp-dropdown .menu {
  text-align: right !important;
  .selected-item{
    padding-right: 0;
    padding-left: 8px;
  }
}

@media screen and (max-width: 640px){
  .service-version-container{
    div.cards-container{
      flex-wrap: nowrap;
    }
  }
}

/*pFinder custom page css*/
.pfinder-center-full-width{
  .form-block{ 
    width: 100%;
    max-width: 504px;
    .search-input {
      input{
        padding: 0 36px 0 15px;
        font-size: 16px;
      }
      button.btn{
        vertical-align: top;
      }
    }
  }
  .serial-number-entry-block.rtl{
    .form-block{
      .search-input {
        input{
          padding: 0 15px 0 36px;
        }
      }
    }
  }
  .detect-device-section, .sign-in-section{
    width: 100%;
    max-width: 504px;
    margin-left: auto;
    margin-right: auto;
  }
  .sign-in-section{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .signed-in-section {
    .rtl.device-toggle-wrapper{
      .device-add{
        .device-add-container{
          .master-pfinder-add-device{
            .search-box{
              .search-input{
                .close-icon{
                  position: absolute;
                  left: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.serial-number-container.pfinder-center-full-width .form-block{
  max-width: 395px;
  .serial-number-title {
    font-size: 20px;
    margin-bottom: 20px !important;
    text-align: center;
  }
  .error-text {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px){
  .serial-number-container.pfinder-center-full-width .form-block {
    .serial-number-title {
      font-size: 18px;
      margin-bottom: 12px;
      width: 100%;
    }
    .search-input {
      text-align: center;
    }
  }
  /*LMI CHAT MOBILE VIEW MODAL Related*/ 
 .lmi-chat-modal{
  .modal-dialog{
    .modal-content{
      width: 100% !important;
      #lmi-chat-container{
        width: 100% !important;
      }
        iframe{
          width: 100% !important;
        }
    }
  }
 }
 .lmi-chat-close-modal{
  .modal-dialog{
    .modal-content{
      width: 100% !important;
    }
  }
 }

}

.serial-number-container.pfinder-center-full-width .rtl{
  .form-block{
    .search-input {
      input{
        padding: 0 15px 0 36px;
      }
      button{
        margin-left: 0;
        margin-right: 30px;
      }
    }
  }
}

.pfinder-carousel-container{
  .contact-pfinder{
    .signed-in-section{
      .device-toggle-wrapper.rtl{
        .device-add-container{
          .master-pfinder-add-device.rtl{
            .search-input{
              a.close-icon{
                position: absolute;
                left: 22px;
              }
            }
          }
        }
      }
    }
  }
}

.phone-link {
  pointer-events: all;
  color: #165DBA;
  outline: none;
  display: inline-block;
}
.phoneNumber {
  font-family: HPSimplified;
}
 // Instant Ink video modal styles
 .instant-ink-window{
  background-color: $porcelain;
  width: 100vw;
  height: 100vh;
  &:after{
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
    top: 30px;
    position: absolute;
    font-size: 40px;
    right: 30px;
    cursor: pointer;
  }
  .modal-header{
    padding: 0px !important;
  }
  .modal-dialog{
      border: 1px solid grey;
      box-sizing: border-box;
  }
}

a.closeBanner{
  text-decoration: none;
  line-height: normal;
  &:hover {
    cursor: pointer;
  }

}

//default SDP Page Theme
.DefaultSdpTheme {
  max-width: 1248px !important;
  margin: auto;
  padding: 0 15px;
  #additional-support .additional-container .wrapper-class {
  //   .disabled{
  //     background-color: #8baedc;
  //     .image-container{
  //       background-color: #8baedc;
  //     }
  //  }
  
   .content {
    p, div {
      font-family: 'Forma DJR Micro';
    }
   }
   .sign-in-container {
    p {
      font-family: 'Forma DJR Micro';
    }
    .sign-in-register-exp .privacy-policy {
      font-family: 'Forma DJR Micro';
    }
    .sign-in-wrapper .secondary-btn {
      line-height: 18px;
    }
   }
  }


  @media screen and (max-width: 768px){ 
    #additional-support .additional-container .wrapper-class { 
      margin: 10px;
    }
  }

  // @media screen and (max-width: 768px){  
  //   margin: 20px;
  // }
}
@media screen and (max-width: 768px){
.instant-ink-window {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
 }
}



.vis-id {
  .app-modal-window .modal-dialog {
    border: 1px solid black;
    width: 1010px;
    .modal-content { 
      border-radius: 0px;
    }}}
    @media #{$small-screen},#{$extra-small-screen} {
      .vis-id {
        .app-modal-window .modal-dialog {
          margin: 21px !important;
          border: 1px solid black;
          min-width: 333px !important;
          max-width: fit-content;
          .modal-content {
            border-radius: 0px;
            .modal-video-container {
              border-radius: 0px;
            }
          }
        }
      }
    }

    body {
      #ngb-live {
        display: none;
      }
    }